import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "src/page/login/login.component";
import { ForgotPasswordComponent } from "src/page/forgot-password/forgot-password.component";
import { ProfileEditComponent } from "src/page/profile-edit/profile-edit.component";
import { LandingComponent } from "src/page/landing/landing.component";
import { ScanQrComponent } from "src/page/scan-qr/scan-qr-component";
import { ListComponent } from "src/component/list/list.component";
import { SingleComponent } from "src/component/single/single.component";
import { AddEditComponent } from "src/component/add-edit/add-edit.component";
import { Authentication } from "src/service/Authentication";
import { Profile } from "../page/profile/profile";

export const routes: Routes = [
  {
    path: "app",
    loadChildren: () =>
      import("../page/tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
    path: "list/:tablename",
    component: ListComponent,
    canActivate: [Authentication],
    data: { login: true, action: "list" },
  },
  {
    path: "single/:tablename/:primary",
    component: SingleComponent,
    canActivate: [Authentication],
    data: { login: true, action: "view" },
  },
  {
    path: "form/:tablename/:action/:primary",
    component: AddEditComponent,
    canActivate: [Authentication],
    data: { login: true, action: "add-edit" },
  },
  {
    path: "profile",
    component: Profile,
    canActivate: [Authentication],
    data: { login: true, permision: "app" },
  },
  {
    path: "profile-edit",
    component: ProfileEditComponent,
    canActivate: [Authentication],
    data: { login: true, permision: "app" },
  },
  {
    path: "scan-qr",
    component: ScanQrComponent,
    canActivate: [Authentication],
    data: { login: true, permision: "app" },
  },
  {
    path: "notification",
    loadChildren: () =>
      import("../page/notification/notification.module").then(
        (m) => m.NotificationPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "ujian/:id",
    loadChildren: () =>
      import("./pages/ujian/ujian.module").then((m) => m.UjianPageModule),
    data: { login: true, permision: "app" },
  },
  {
    path: "materi-jenjang/:type",
    loadChildren: () =>
      import("./pages/materi-jenjang/materi-jenjang.module").then(
        (m) => m.MateriJenjangPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "materi-kelas/:type",
    loadChildren: () =>
      import("./pages/materi-kelas/materi-kelas.module").then(
        (m) => m.MateriKelasPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "materi-mapel/:type",
    loadChildren: () =>
      import("./pages/materi-mapel/materi-mapel.module").then(
        (m) => m.MateriMapelPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "materi-jurusan/:type",
    loadChildren: () =>
      import("./pages/materi-jurusan/materi-jurusan.module").then(
        (m) => m.MateriJurusanPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "custom-filter/:type",
    loadChildren: () =>
      import("./pages/custom-filter/custom-filter.module").then(
        (m) => m.CustomFilterPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "presensi",
    loadChildren: () =>
      import("./pages/presensi/presensi.module").then(
        (m) => m.PresensiPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "jadwal",
    loadChildren: () =>
      import("./pages/jadwal/jadwal.module").then((m) => m.JadwalPageModule),
  },
  {
    path: "jadwal-detail/:id",
    loadChildren: () =>
      import("./pages/jadwal-detail/jadwal-detail.module").then(
        (m) => m.JadwalDetailPageModule
      ),
  },
  {
    path: "map-soal",
    loadChildren: () =>
      import("./pages/map-soal/map-soal.module").then(
        (m) => m.MapSoalPageModule
      ),
  },
  {
    path: "pembahasan",
    loadChildren: () =>
      import("./pages/pembahasan/pembahasan.module").then(
        (m) => m.PembahasanPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "presensi-list",
    loadChildren: () =>
      import("./pages/presensi-list/presensi-list.module").then(
        (m) => m.PresensiListPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "filter-notif",
    loadChildren: () =>
      import("./pages/filter-notif/filter-notif.module").then(
        (m) => m.FilterNotifPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "moda-notif",
    loadChildren: () =>
      import("./page/modal/moda-notif/moda-notif.module").then(
        (m) => m.ModaNotifPageModule
      ),
    data: { login: true, permision: "app" },
  },
  {
    path: "notification-detail/:id",
    loadChildren: () =>
      import("./pages/notification-detail/notification-detail.module").then(
        (m) => m.NotificationDetailPageModule
      ),
    data: { login: true, permision: "app" },
  },
  { path: "landing", component: LandingComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "", component: LoginComponent },
  { path: "**", redirectTo: "/" },
  { path: "login", component: LoginComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
