// const Databases = {
//   Table_name: {
//     table_spec: { name: "Table Name", label: "Label Display", add: "TRUE => SHOW, FALSE => HIDE", edit: "TRUE => SHOW, FALSE => HIDE", delete: "TRUE => SHOW, FALSE => HIDE" },
//     filter: "",
//     primary: "PRIMARY FIELD ",
//     masterdetail: [{ DetailTable: "detail_barang_keluar", MasterField: "id", DetailField: "barang_keluar_id" }],
//     field: [
//       {
//         name: "id", label: "ID", type: "NO",
//         number: false, list: false, view: false, add: false, edit: false, filtersearch: false,
//         relation: false, relation_table: "", relation_field: "",
//         relation_display: "", parent_filter: [],
//         required: false, readonly: false, default: "",
//         toggle: { pengiriman_id: { value: ["2"] }, po_id: { value: ["1"] } } //SHOW HIDE FIELD ON ADD/EDIT
//       },
//     ]
//   }
// }

export const Database: any = {
  jadwal: {
    table: {
      name: "jadwal",
      label: "Jadwal ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tentor_id",
        label: "Tentor",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "tentor",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "siswa_id",
        label: "Siswa",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "siswa",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "mapel",
        label: "Mapel",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "paket_id",
        label: "Paket",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "paket",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tanggal",
        label: "Tanggal",
        primary: false,
        type: "DATE",
        validate: "DATE",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jam",
        label: "Jam",
        primary: false,
        type: "TIME",
        validate: "TIME",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "hari",
        label: "Hari",
        primary: false,
        type: "SELECT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
        select: [
          { value: "2", label: "Senin" },
          { value: "3", label: "Selasa" },
          { value: "4", label: "Rabu" },
          { value: "5", label: "Kamis" },
          { value: "6", label: "Jumat" },
          { value: "7", label: "Sabtu" },
          { value: "1", label: "Minggu" },
        ],
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  jenjang_pendidikan: {
    table: {
      name: "jenjang_pendidikan",
      label: "Jenjang Pendidikan ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  kelas: {
    table: {
      name: "kelas",
      label: "Kelas ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama Kelas",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      // {
      //   name: "jurusan",
      //   label: "Jurusan",
      //   primary: false,
      //   type: "TEXT",
      //   validate: "STRING",
      //   number: false,
      //   list: true,
      //   view: true,
      //   add: true,
      //   edit: true,
      //   sort: true,
      //   filtersearch: false,
      //   relation: false,
      //   relation_table: "",
      //   relation_field: "",
      //   relation_display: "",
      //   parent_filter: [],
      //   required: false,
      //   readonly: false,
      //   default: "",
      //   file: false,
      // },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  jurusan: {
    table: {
      name: "jurusan",
      label: "Jurusan",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama Jurusan",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jurusan",
        label: "Jurusan",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  latihan_soal: {
    table: {
      name: "latihan_soal",
      label: "Latihan Soal Bimbel",
      add: false,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    custom_view: true,
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama ",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "mapel_id",
        label: "Mata Pelajran",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "mapel",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id", "kelas_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "file",
        label: "File",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "file_pembahasan",
        label: "File Pembahasan",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "audio",
        label: "Audio",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "youtube",
        label: "Youtube",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: false,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "status",
        label: "Status",
        primary: false,
        type: "RADIO",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: false,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "c",
        file: false,
        select: [
          { value: "o", label: "Open" },
          { value: "c", label: "Close" },
        ],
      },
      {
        name: "na",
        label: "Akitif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  latihan_soal_nb: {
    table: {
      name: "latihan_soal_nb",
      label: "Latihan Soal",
      add: false,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    custom_view: true,
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama ",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },

      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "mapel_id",
        label: "Mata Pelajran",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "mapel",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id", "kelas_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "file",
        label: "File",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "audio",
        label: "Audio",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "youtube",
        label: "Youtube",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: false,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "status",
        label: "Status",
        primary: false,
        type: "RADIO",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: false,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "c",
        file: false,
        select: [
          { value: "o", label: "Open" },
          { value: "c", label: "Close" },
        ],
      },
      {
        name: "na",
        label: "Akitif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  lokasi: {
    table: {
      name: "lokasi",
      label: "Lokasi Bimbel ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "alamat",
        label: "Alamat",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kota",
        label: "Kota",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "latitude",
        label: "Latitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "longiteude",
        label: "Longitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  mapel: {
    table: {
      name: "mapel",
      label: "Mata Pelajaran ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "icon",
        label: "Icon",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  materi: {
    table: {
      name: "materi",
      label: "Materi ",
      add: false,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    custom_view: true,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "mapel_id",
        label: "Mata Pelajaran",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "mapel",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id", "kelas_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "file",
        label: "File",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: true,
      },
      {
        name: "youtube",
        label: "Youtube",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: false,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  youtube: {
    table: {
      name: "youtube",
      label: "Youtube",
      add: false,
      edit: false,
      delete: false,
      view: true,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    custom_view: true,
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama ",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "mapel_id",
        label: "Mata Pelajran",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "mapel",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id", "kelas_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "youtube",
        label: "Youtube",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: false,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Akitif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  paket_program: {
    table: {
      name: "paket_program",
      label: "Paket Program ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "program_id",
        label: "Program",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "program",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "paket_id",
        label: "Paket",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "paket",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "pertemuan_id",
        label: "Pertemuan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "pertemuan",
        relation_field: "id",
        relation_display: "pertemuan",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "harga",
        label: "Harga",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  peserta_ujian: {
    table: {
      name: "peserta_ujian",
      label: "Peserta Ujian ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "user_id",
        label: "User",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "users",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "ujian_id",
        label: "Ujian",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "ujian",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "skor",
        label: "Skor",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: true,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  presensi: {
    table: {
      name: "presensi",
      label: "Presensi ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jadwal_id",
        label: "Jadwal",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: false,
        filtersearch: false,
        relation: true,
        relation_table: "jadwal",
        relation_field: "id",
        relation_display: "tanggal",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tentor_id",
        label: "Tentor",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "tentor",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "siswa_id",
        label: "Siswa",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "siswa",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tanggal",
        label: "Tanggal",
        primary: false,
        type: "DATE",
        validate: "DATE",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "mapel",
        label: "Mapel",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "paket_id",
        label: "Paket",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "paket",
        relation_field: "id",
        relation_display: "kode",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jam",
        label: "Jam",
        primary: false,
        type: "TIME",
        validate: "TIME",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "durasi_paket",
        label: "Durasi Paket",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "durasi_pertemuan",
        label: "Durasi Pertemanan",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "address",
        label: "Lokasi",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "longitude",
        label: "Longitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "latitude",
        label: "Latitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "keterangan",
        label: "Keterangan",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: false,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
    ],
  },
  tentor: {
    table: {
      name: "tentor",
      label: "Tentor ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "foto",
        label: "Foto Profil",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nik",
        label: "NIK",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "hp",
        label: "No. Hp",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "alamat",
        label: "Alamat",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "lattitude",
        label: "Latitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "longitude",
        label: "Longitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "level_id",
        label: "Level",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: false,
        filtersearch: false,
        relation: true,
        relation_table: "userlevels",
        relation_field: "userlevelid",
        relation_display: "userlevelname",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "username",
        label: "Username",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "password",
        label: "Password",
        primary: false,
        type: "PASSWORD",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      // {
      //   name: "lokasi_id",
      //   label: "Lokasi Mengajar",
      //   primary: false,
      //   type: "LOOKUP",
      //   validate: "INTEGER",
      //   number: true,
      //   list: false,
      //   view: true,
      //   add: true,
      //   edit: true,
      //   sort: true,
      //   filtersearch: true,
      //   relation: true,
      //   relation_table: "lokasi",
      //   relation_field: "id",
      //   relation_display: "nama",
      //   parent_filter: [],
      //   required: true,
      //   readonly: false,
      //   default: "",
      //   file: false,
      // },
      {
        name: "keterangan",
        label: "Keterangan",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "status",
        label: "Status",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "0",
        file: false,
        select: [
          { value: "0", label: "0" },
          { value: "1", label: "1" },
        ],
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  ujian: {
    table: {
      name: "ujian",
      label: "Ujian ",
      add: false,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: [
      // {
      //   DetailTable: "peserta_ujian",
      //   MasterField: "id",
      //   DetailField: "ujian_id",
      // },
      {
        DetailTable: "soal_u",
        MasterField: "id",
        DetailField: "ujian_id",
      },
    ],
    primary: "id",
    custom_view: true,
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "mapel_id",
        label: "Mata Pelajaran",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "mapel",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id", "kelas_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id"],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "file",
        label: "File",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "durasi_mulai",
        label: "Mulai Audio Pada Menit ke ?",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: true,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "waktu",
        label: "Waktu ( Menit )",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "keterangan",
        label: "Keterangan",
        primary: false,
        type: "TEXT",
        validate: "TEXT",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "status",
        label: "Status",
        primary: false,
        type: "RADIO",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "c",
        file: false,
        select: [
          { value: "o", label: "Open" },
          { value: "c", label: "Close" },
        ],
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  userlevelpermissions: {
    table: {
      name: "userlevelpermissions",
      label: "Permission ",
      add: false,
      edit: false,
      delete: false,
      view: false,
      list: false,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "userlevelid",
        label: "Userlevelid",
        primary: true,
        type: "TEXT",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tablename",
        label: "Tablename",
        primary: true,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "permission",
        label: "Permission",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
    ],
  },
  userlevels: {
    table: {
      name: "userlevels",
      label: "User Level ",
      add: false,
      edit: false,
      delete: false,
      view: false,
      list: false,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "userlevelid",
        label: "Userlevelid",
        primary: true,
        type: "TEXT",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "userlevelname",
        label: "Userlevelname",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
    ],
  },
  users: {
    table: {
      name: "users",
      label: "Users ",
      add: false,
      edit: false,
      delete: false,
      view: false,
      list: false,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "foto",
        label: "Foto Profil",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nik",
        label: "NIK",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "alamat",
        label: "Alamat",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tanggal_lahir",
        label: "Tanggal Lahir",
        primary: false,
        type: "DATE",
        validate: "DATE",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "hp",
        label: "No. Hp",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "asal_sekolah",
        label: "Asal Sekolah",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama_wali",
        label: "Nama Wali",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id"],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jurusan_id",
        label: "Jurusan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jurusan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["kelas_id"],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "lokasi_id",
        label: "Lokasi Bimbel",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "lokasi",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tanggal_mulai",
        label: "Tanggal Mulai",
        primary: false,
        type: "DATE",
        validate: "DATE",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "keterangan",
        label: "Keterangan",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "status",
        label: "Status",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "0",
        file: false,
        select: [
          { value: "0", label: "0" },
          { value: "1", label: "1" },
        ],
      },
      {
        name: "username",
        label: "Username",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "password",
        label: "Password",
        primary: false,
        type: "PASSWORD",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "level_id",
        label: "Level",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "userlevels",
        relation_field: "userlevelid",
        relation_display: "userlevelname",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "paket_id",
        label: "Paket",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "paket",
        relation_field: "id",
        relation_display: "kode",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "program_id",
        label: "Program",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "program",
        relation_field: "id",
        relation_display: "kode",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "pertemuan_id",
        label: "Pertemuan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "pertemuan",
        relation_field: "id",
        relation_display: "pertemuan",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "paket_program_id",
        label: "Harga Paket Program",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "paket_program",
        relation_field: "id",
        relation_display: "harga",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "lattitude",
        label: "Latitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "longitude",
        label: "Longitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  pilihan_jawaban_u: {
    table: {
      name: "pilihan_jawaban_u",
      label: "Pilihan Jawaban ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "soal_id",
        label: "Soal",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: false,
        add: true,
        edit: false,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "soal_u",
        relation_field: "id",
        relation_display: "soal",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jawaban",
        label: "Jawaban",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
        select: [
          { value: "y", label: "y" },
          { value: "n", label: "n" },
        ],
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "n", label: "Ya" },
          { value: "y", label: "Tidak" },
        ],
      },
    ],
  },
  soal_u: {
    table: {
      name: "soal_u",
      label: "Soal Ujian ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: [
      {
        DetailTable: "pilihan_jawaban_u",
        MasterField: "id",
        DetailField: "soal_id",
      },
    ],
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "ujian_id",
        label: "Ujian",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: false,
        add: true,
        edit: false,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "ujian",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "file",
        label: "Gambar",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "audio",
        label: "Audio",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "soal",
        label: "Soal",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "bobot",
        label: "Bobot",
        primary: false,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: true,
        edit: true,
        sort: false,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "n", label: "Ya" },
          { value: "y", label: "Tidak" },
        ],
      },
    ],
  },
  paket: {
    table: {
      name: "paket",
      label: "Paket ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kode",
        label: "Kode Paket",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama Paket",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "durasi",
        label: "Durasi Paket (menit)",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "n", label: "Ya" },
          { value: "y", label: "Tidak" },
        ],
      },
    ],
  },
  pertemuan: {
    table: {
      name: "pertemuan",
      label: "Pertemuan ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "pertemuan",
        label: "Pertemuan",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Durasi Paket (menit)",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "n", label: "Ya" },
          { value: "y", label: "Tidak" },
        ],
      },
    ],
  },
  program: {
    table: {
      name: "program",
      label: "Program ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kode",
        label: "Kode Program",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama Program",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "n", label: "Ya" },
          { value: "y", label: "Tidak" },
        ],
      },
    ],
  },
  m_permintaan: {
    table: {
      name: "m_permintaan",
      label: "Permintaan ",
      add: true,
      edit: true,
      delete: false,
      view: true,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "user_id",
        label: "Pengguna",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: false,
        list: false,
        view: true,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "users",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenis_permintaan_id",
        label: "Jenis Permintaan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "jenis_permintaan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama Permintaan",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "keterangan",
        label: "Keterangan",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tanggal",
        label: "Tanggal",
        primary: false,
        type: "DATE",
        validate: "DATE",
        number: false,
        list: false,
        view: true,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "status",
        label: "Status",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: false,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "0",
        file: false,
        select: [
          { value: "0", label: "Menunggu" },
          { value: "1", label: "Setujui" },
          { value: "2", label: "Tolak" },
        ],
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "n", label: "Ya" },
          { value: "y", label: "Tidak" },
        ],
      },
    ],
  },
  siswa: {
    table: {
      name: "siswa",
      label: "Siswa ",
      add: true,
      edit: true,
      delete: true,
      view: false,
      list: true,
    },
    filter: "",
    masterdetail: false,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "foto",
        label: "Foto Profil",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama",
        label: "Nama",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nis",
        label: "NIS",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "alamat",
        label: "Alamat",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tanggal_lahir",
        label: "Tanggal Lahir",
        primary: false,
        type: "DATE",
        validate: "DATE",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "hp",
        label: "No. Hp",
        primary: false,
        type: "TEXT",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "asal_sekolah",
        label: "Asal Sekolah",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "nama_wali",
        label: "Nama Wali",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jenjang_id",
        label: "Jenjang Pendidikan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jenjang_pendidikan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "kelas_id",
        label: "Kelas",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "kelas",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["jenjang_id"],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "jurusan_id",
        label: "Jurusan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "jurusan",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: ["kelas_id"],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "lokasi_id",
        label: "Lokasi Belajar",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: true,
        relation_table: "lokasi",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "tanggal_mulai",
        label: "Tanggal Mulai",
        primary: false,
        type: "DATE",
        validate: "DATE",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "keterangan",
        label: "Keterangan",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "status",
        label: "Status",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: true,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "0",
        file: false,
        select: [
          { value: "0", label: "Aktif" },
          { value: "1", label: "Non Aktif" },
        ],
      },
      {
        name: "username",
        label: "Username",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "password",
        label: "Password",
        primary: false,
        type: "PASSWORD",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: true,
        edit: true,
        sort: false,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "level_id",
        label: "Level",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: false,
        filtersearch: false,
        relation: true,
        relation_table: "userlevels",
        relation_field: "userlevelid",
        relation_display: "userlevelname",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "paket_id",
        label: "Paket",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: false,
        filtersearch: false,
        relation: true,
        relation_table: "paket",
        relation_field: "id",
        relation_display: "kode",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "program_id",
        label: "Program",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: false,
        filtersearch: false,
        relation: true,
        relation_table: "program",
        relation_field: "id",
        relation_display: "kode",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "pertemuan_id",
        label: "Pertemuan",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: true,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: false,
        filtersearch: false,
        relation: true,
        relation_table: "pertemuan",
        relation_field: "id",
        relation_display: "pertemuan",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "paket_program_id",
        label: "Harga Paket Program",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: false,
        filtersearch: false,
        relation: true,
        relation_table: "paket_program",
        relation_field: "id",
        relation_display: "harga",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "lattitude",
        label: "Latitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "longitude",
        label: "Longitude",
        primary: false,
        type: "TEXT",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "na",
        label: "Aktif ?",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "n",
        file: false,
        select: [
          { value: "y", label: "Tidak" },
          { value: "n", label: "Ya" },
        ],
      },
    ],
  },
  homework: {
    table: {
      name: "homework",
      label: "Pekerjaan Rumah ",
      add: true,
      edit: false,
      delete: false,
      view: true,
      list: true,
    },
    filter: "",
    masterdetail: false,
    // masterdetail: [{ DetailTable: "homework_answer", MasterField: "id", DetailField: "homework_id" }],
    custom_view: true,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "user_id",
        label: "Siswa",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "siswa",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "question",
        label: "Pertanyaan",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "foto",
        label: "Gambar",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: false,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: true,
        readonly: false,
        default: "",
        file: true,
      },
      {
        name: "datetime",
        label: "Tanggal",
        primary: false,
        type: "DATE",
        validate: "DATE",
        number: false,
        list: true,
        view: true,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "status",
        label: "Status",
        primary: false,
        type: "CHECKBOX",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: false,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "open",
        file: false,
        select: [
          { value: "open", label: "Belum Terjawab" },
          { value: "close", label: "Terjawab" },
        ],
      },
    ],
  },
  homework_answer: {
    table: {
      name: "homework_answer",
      label: "Jawaban Pekerjaan Rumah ",
      add: true,
      edit: false,
      delete: false,
      view: true,
      list: true,
    },
    filter: "",
    masterdetail: false,
    custom_view: true,
    primary: "id",
    field: [
      {
        name: "id",
        label: "Id",
        primary: true,
        type: "NO",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "homework_id",
        label: "PR",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: false,
        list: false,
        view: false,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "homework",
        relation_field: "id",
        relation_display: "id",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "user_id",
        label: "Siswa",
        primary: false,
        type: "LOOKUP",
        validate: "INTEGER",
        number: false,
        list: true,
        view: true,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "siswa",
        relation_field: "id",
        relation_display: "nama",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "answer",
        label: "Jawaban",
        primary: false,
        type: "TEXTAREA",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "foto",
        label: "Foto",
        primary: false,
        type: "FILE",
        validate: "STRING",
        number: false,
        list: true,
        view: true,
        add: true,
        edit: true,
        sort: true,
        filtersearch: false,
        relation: false,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
      {
        name: "datetime",
        label: "Tanggal",
        primary: false,
        type: "DATE",
        validate: "DATE",
        number: false,
        list: true,
        view: true,
        add: false,
        edit: false,
        sort: true,
        filtersearch: false,
        relation: true,
        relation_table: "",
        relation_field: "",
        relation_display: "",
        parent_filter: [],
        required: false,
        readonly: false,
        default: "",
        file: false,
      },
    ],
  },
};
