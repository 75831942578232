import { Authentication } from "./../../../../service/Authentication";
import { Util } from "./../../../../service/util";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { Component, OnInit } from "@angular/core";
import swal from "sweetalert";

@Component({
  selector: "app-moda-notif",
  templateUrl: "./moda-notif.page.html",
  styleUrls: ["./moda-notif.page.scss"],
})
export class ModaNotifPage implements OnInit {
  datas = [];
  title: any;
  msg: any;

  jadwal_ids: any = [];
  notif_id: any;

  constructor(
    public modalCtrl: ModalController,
    public navParam: NavParams,
    public alertController: AlertController,
    public util: Util,
    public auth: Authentication
  ) {}

  ngOnInit() {
    let param = this.navParam.data;
    console.log(param);
    this.title = param.title;
    this.msg = param.msg;
    this.datas = param.jadwal;
    this.notif_id = param.notif_id;

    this.datas.forEach((data) => {
      let jadwal_id = data.id;
      this.jadwal_ids.push(jadwal_id);
    });
  }

  async hadirPrompt(data) {
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: "Request Materi ?",
      backdropDismiss: true,
      inputs: [
        {
          name: "keterangan",
          type: "textarea",
          placeholder: "Silahkan isi request materi di sini jika ada...",
        },
      ],
      buttons: [
        {
          text: "Kirim",
          handler: (handler) => {
            data.jenis_notif = 1;
            data.jenis_kehadiran = 1;
            data.keterangan = handler.keterangan;
            this.prosesNotif(data);
            // console.log("Confirm Ok", handler.value);
          },
        },
      ],
    });

    await alert.present();
  }

  async reschedulPrompt(data) {
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: "Request Jadwal ?",
      backdropDismiss: true,
      inputs: [
        {
          name: "keterangan",
          type: "textarea",
          placeholder: "Silahkan isi request jadwal di sini..",
        },
      ],
      buttons: [
        {
          text: "Kirim",
          handler: (handler) => {
            // console.log("Confirm Ok", handler.keterangan);
            data.jenis_notif = 1;
            data.jenis_kehadiran = 2;
            data.keterangan = handler.keterangan;
            this.prosesNotif(data);
          },
        },
      ],
    });

    await alert.present();
  }

  async liburConfirm(data) {
    console.log("Libur dari Promp", data);
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: "Apakah anda yakin libur ?",
      message:
        "Anda terkena denda.</br>Jumlah denda akan disesuaikan Dengan perjanjian kerja",
      buttons: [
        {
          text: "Batal",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Libur",
          cssClass: "libur",
          handler: () => {
            data.jenis_notif = 1;
            data.jenis_kehadiran = 3;
            this.prosesNotif(data);
          },
        },
      ],
    });

    await alert.present();
  }

  async prosesNotif(data) {
    let user_id = this.auth.profile.id;
    let level_id = this.auth.profile.level_id;
    if (user_id) {
      data.penerima_id = "30"; //admin
      data.pengirim_id = user_id;
      data.level_id = level_id;
    }

    let datas = {
      data: data,
      jadwal_ids: this.jadwal_ids,
      notif_id: this.notif_id,
    };
    console.log(datas);
    await this.util
      .setNotif(datas)
      .then((res) => {
        console.log("RESPON API : ", res);

        swal({
          title: `${res.title}`,
          text: `${res.msg}`,
          icon: "success",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then((res) => {
          if (res) {
            // this.navCtrl.back();

            this.datas.filter(async (result, index) => {
              if (result.id == data.id) {
                this.datas.splice(index, 1);
                if (this.datas.length < 1) {
                  await this.modalCtrl.dismiss();
                }
              }
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // async letter() {
  //   const onClosedData: string = "Nanti";
  //   await this.modalCtrl.dismiss(onClosedData);
  // }

  async letter() {
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: `Peringatan!`,
      message: `Pastikan anda konfirmasi kehadiran nanti.`,
      // message: `${message}`,
      backdropDismiss: false,
      buttons: [
        {
          text: "Ok",
          cssClass: "primary",
          handler: async () => {
            console.log("presensi nanti.");
            await this.modalCtrl.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }
}
