import { Component, OnInit, NgZone } from '@angular/core'
import { NavController } from '@ionic/angular'
// import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx'
import { Authentication } from 'src/service/Authentication'
import { Util } from 'src/service/util'
import { NavigationExtras } from '@angular/router'
import swal from 'sweetalert'

@Component({
  selector: 'app-scan-qr-component',
  templateUrl: 'scan-qr-component.html',
  styleUrls: ['scan-qr-component.scss']
})
export class ScanQrComponent implements OnInit {
  cameraPermision = true
  proyek: any
  ShowQr: any = false
  valuescan: any
  scanSubscription: any
  camera_flash: string = "OFF";
  constructor(
    // private qr_scan: QRScanner,
    private navCtrl: NavController,
    public auth: Authentication,
    public util: Util,
    private ngZone: NgZone,

  ) {
    this.initQRScanner()
  }
  ngOnInit() {

  }

  ionViewWillEnter() {
    this.initQRScanner()
  }

  ionViewWillLeave() {
    this.stopScanning()
  }

  async initQRScanner() {
    // (window.document.querySelector("ion-app") as HTMLElement).classList.add(
    //   "cameraView"
    // )
    // await this.qr_scan.prepare()
    //   .then((status: QRScannerStatus) => {
    //     if (status.authorized) {
    //       this.qr_scan.useBackCamera()
    //       this.qr_scan.show()
    //       let scanSub = this.qr_scan.scan().subscribe((text: string) => {
    //         this.valuescan = text
    //         this.qr_scan.hide()
    //         scanSub.unsubscribe()
    //         this.onScanned()
    //       })
    //     } else if (status.denied) {
    //       this.cameraPermision = false
    //       this.qr_scan.openSettings()
    //     } else {
    //       this.ShowQr = false
    //     }
    //   })
    //   .catch((e: any) => console.log('Error is', e))
  }

  onScanned() {
    this.stopScanning()
    this.ShowQr = true
    var splitted = this.valuescan.split("-")
    let url = ''
    let id = ''
    if (splitted != null && splitted != undefined) {
      id = splitted[1]
      if (splitted[0] == 'Pembayaran') {
        url = '/penerimaan-detail'
        this.GotoDetail(url, id)
      } else if (splitted[0] == 'PO') {
        url = '/purchase-order-detail'
        this.GotoDetail(url, id)
      } else {
        swal("Qr Invalid !!","", "error")
        this.initQRScanner()
      }
    } else {
      this.initQRScanner()
    }

  }

  stopScanning() {
    this.scanSubscription ? this.scanSubscription.unsubscribe() : null
    this.scanSubscription = null;
    (window.document.querySelector("ion-app") as HTMLElement).classList.remove(
      "cameraView"
    )
    // this.qr_scan.hide()
    // this.qr_scan.destroy()
  }
  onFlashCamera() {
    // if (this.camera_flash == "OFF") {
    //   this.camera_flash = "ON"
    //   this.qr_scan.enableLight()
    // } else {
    //   this.camera_flash = "OFF"
    //   this.qr_scan.disableLight()
    // }
  }

  GotoDetail(url: any = 'app/home', id: any = 0) {
    this.ngZone.run(() => {
      this.util.presentLoading()
      let params: NavigationExtras = {
        queryParams: { id: id },
      }
      this.navCtrl.navigateForward([url], params)
    })
  }

}
