import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import {
  IonContent,
  NavController,
  IonSearchbar,
  AlertController,
  Platform,
} from '@ionic/angular';
import { ApiService } from 'src/service/api.service';
import { Config } from 'src/service/config';
import { Util } from 'src/service/util';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Database } from 'src/model/database';
import swal from 'sweetalert';
import { Authentication } from 'src/service/Authentication';
import { FilterService } from 'src/app/service/filter.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @ViewChild(IonContent) ionContent: IonContent;
  @ViewChild('autofocus', { static: false }) searchbar: IonSearchbar;

  TableName: any = '';
  PrimaryParamName: any = [];
  ScollTop: boolean = false;
  title: any = '';
  loading: any = true;
  complete: any = false;
  loadingCount: any = new Array(4);
  Data: any = [];
  show_filter: any = false;
  show_order: any = false;
  order_list: any;
  filter: any = {};
  order: any = [];
  sort: any = 'DESC';
  limit: any = 20;
  offset: any = 0;
  search: any;
  Database: any = [];
  ListField: any = [];
  FilterSearch: any = [];
  fileUrl: string;
  searchPanel: boolean = false;
  PageMeta: any = [];
  add: boolean = false;
  profile: any;
  level_id: any;
  jenjang_id: any;
  kelas_id: any;
  mapel_id: any;
  user_id: any;
  kontak_admin: any;

  showAction = true;

  hideBtnFilter = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public config: Config,
    public api: ApiService,
    public navCtrl: NavController,
    public util: Util,
    public auth: Authentication,
    public alertCtrl: AlertController,
    private iab: InAppBrowser,
    private platform: Platform,
    private filterService: FilterService
  ) {
    this.Database = Database;
    this.fileUrl = config.fileUrl;
    this.level_id = this.auth.level_id;

    this.profile = JSON.parse(localStorage.getItem('profile'));

    if (this.profile) {
      this.level_id = this.profile.level_id;
      this.user_id = this.profile.id;
    }

    if (this.level_id == 100) {
      this.showAction = false;
    }

    if (this.level_id == 20) {
      this.hideBtnFilter = true;
    }
  }

  ngOnInit() {
    this.util.LookupChildFilter = {};
    this.util.LookupParentFilter = {};
    this.util.FormField = {};
    this.util.TypeInput = [];
    this.ListField = [];
    this.FilterSearch = [];
    this.TableName = this.activatedRoute.snapshot.paramMap.get('tablename');
    this.PageMeta = this.Database[this.TableName];
    this.add = this.PageMeta.table.add;
  }

  async ionViewWillEnter() {
    let Resp = await this.util.InputFormBuilder('filtersearch', this.TableName);
    if (Resp) {
      this.title = Resp.pagemeta.table.label;
      this.PrimaryParamName = Resp.pagemeta.primary;
      this.ListField = Resp.ListField;
      this.FilterSearch = Resp.TypeInput;
      this.complete = true;
    }
    if (
      this.TableName == 'materi' ||
      this.TableName == 'latihan_soal' ||
      this.TableName == 'latihan_soal_nb'||
      this.TableName == 'youtube'
    ) {
      await this.getParam();
      this.hideBtnFilter = true;
    }

    await this.DefaultFilter();
    await this.GetData();
  }

  async getParam() {
    this.activatedRoute.queryParams.subscribe((p) => {
      // console.log("Param : ", p);
      this.jenjang_id = p.jenjang_id;
      this.kelas_id = p.kelas_id;
      this.mapel_id = p.mapel_id;
    });

    this.jenjang_id = this.profile.jenjang_id;
    this.kelas_id = this.profile.kelas_id;
    this.mapel_id = this.profile.mapel_id;

    // if (this.level_id === 20) {
    //   const defaultFilter = {
    //     jenjang_id: this.jenjang_id,
    //     kelas_id: this.kelas_id,
    //   };
    //   this.filterService.set(this.TableName, defaultFilter);
    // }
  }

  doRefresh(event: any) {
    this.loading = true;
    this.GetData();
    event.target.complete();
  }

  DefaultFilter(e: any = 0) {
    if (this.FilterSearch) {
      for (let item of this.FilterSearch) {
        this.filter[item.name] = '';
        this.util.FormInput.controls[item.name].setValue();
        if (item.type == 'LOOKUP') {
          let lkp = item.name + 's_lkp';

          this.util.FormInput.controls[lkp].setValue({
            relation: '',
            display: '',
          });
        }
      }
    }

    if (
      this.TableName == 'materi' ||
      this.TableName == 'latihan_soal' ||
      this.TableName == 'latihan_soal_nb'||
      this.TableName == 'youtube'
    ) {
      let filter = this.filterService.get(this.TableName);

      if (filter) {
        for (const f in filter) {
          this.filter[f] = filter[f];
        }
      }

      // this.filter.jenjang_id = this.jenjang_id;
      // this.filter.kelas_id = this.kelas_id;
      // this.filter.mapel_id = this.mapel_id;
    }
    //get filter service
    // let tmpFilter = this.filterService.get(this.TableName);
    // for (const key in tmpFilter) {
    //   const value = tmpFilter[key];
    //   console.log(value);
    // }
    // console.log("Temp Filter : ", tmpFilter);

    this.order = '';
    e == 1 ? this.GetData() : '';
  }

  // FILTER SEARCH
  Onfilter() {
    this.loading = true;
    if (this.FilterSearch) {
      for (let item of this.FilterSearch) {
        this.filter[item.name] = this.util.FormInput.value[item.name];
      }
    }

    // let tmp = {};
    // for (const key in this.filter) {
    //   let name = key + "s_lkp";
    //   let value = this.util.FormInput.value[name];
    //   tmp[name] = value;
    // }
    // tmp = {
    //   ...this.filter,
    //   ...tmp,
    // };

    // console.log(tmp);
    // this.filterService.set(this.TableName, tmp);
    this.GetData();
  }

  OnOrder() {
    // console.log(this.order);
    this.show_order = !this.show_order;
    this.GetData();
  }

  onCancelSearch() {
    this.searchPanel = false;
    this.loading = true;
    this.search = '';
    this.GetData();
  }
  // END FILTER SEARCH

  // GET DATA LIST
  async GetData(offset: any = 0, originOffset: any = 0) {
    // console.log("Level : ", this.level_id);
    this.show_filter = false;
    this.offset = offset;
    if (offset == 0) {
      this.Data = [];
    }

    if (
      this.TableName == 'materi' ||
      this.TableName == 'latihan_soal' ||
      this.TableName == 'latihan_soal_nb'
    ) {
      if (this.level_id == 100 || this.level_id == '100') {
        this.limit = 3;
        this.kontak_admin = localStorage.getItem('kontak_admin');
      }
      this.order = ['nama'];
    }

    let body = {
      order: this.order,
      sort: this.sort,
      limit: this.limit,
      offset: this.offset,
      filter: this.filter,
      search: this.search,
    };

    // console.log("Filter BEfore Req", this.filter);

    if (
      this.TableName == 'materi' ||
      this.TableName == 'latihan_soal' ||
      this.TableName == 'latihan_soal_nb'
    ) {
      if (this.level_id == 100 || this.level_id == '100') {
        this.limit = 2;
        // this.alertGuest();
      }
    }

    let get = await this.api.List(this.TableName, body);
    if (get) {
      for (let item of get) {
        let no = 0;
        let record = [];
        item.thumbnail = false;
        // SET DATA INTO LIST
        console.log('this.ListField: ', this.ListField);
        for (let page of this.ListField) {
          no++;
          let display = page.name;
          if (page.relation) {
            display = page.relation_table + '_' + page.relation_display;
          } else {
            if (page.type == 'SELECT' || page.type == 'RADIO') {
              page.selector = {};
              for (let select of page.select) {
                item[page.name + '_' + select.value] = select.label;
              }
              display = page.name + '_' + item[page.name];
            }
          }
          // SET ARRAY DATA LIST
          item['list_' + no] = item[display];
          if (page.type == 'FILE' && !item.thumbnail) {
            item.thumbnail = true;
            item.ThumbnailFile = item[display];
            item['list_' + no] = '';
          }
          if (no != 1) {
            record.push('list_' + no);
          }
          // END SET ARRAY DATA LIST
        }
        // END SET DATA INTO LIST

        item.records = record;
        this.Data.push(item);
        console.log('this.Data: ', this.Data);
        if (this.Data.length > 15) {
          // this.ScollTop = true;
        }
      }
    } else {
      this.offset = originOffset;
    }
    setTimeout(() => {
      this.loading = false;
    }, 250);

    if (
      this.TableName == 'materi' ||
      this.TableName == 'latihan_soal' ||
      this.TableName == 'latihan_soal_nb'
    ) {
      if (this.level_id == 100 || this.level_id == '100') {
        // this.limit = 2;
        // this.alertGuest();
        console.log('alert guest');
      }
    }
  }
  // GET DATA LIST

  loadData(event) {
    this.loading = false;
    setTimeout(() => {
      event.target.complete();
      let offset = this.offset + this.limit;
      if (this.level_id != '100') {
        this.GetData(offset, this.offset);
      }

      // this.GetData(offset, this.offset);
    }, 500);
  }

  Search(event) {
    this.loading = true;
    this.search = event.target.value;
    this.Data = [];
    this.GetData();
  }

  GoSinggle(data: any) {
    console.log('data: ', data);
    // this.profile = JSON.parse(localStorage.getItem("profile"));
    // let level_id = Number(this.profile.level_id);

    if (this.TableName == 'ujian') {
      if (this.level_id == -1 || this.level_id == 10) {
        this.util.presentToast(
          'Hanya siswa yang dapat melakukan ujian.',
          'bottom',
          'dark'
        );
      } else {
        // this.alertUjian(data);
        this.cekPesertaUjian(data);
      }
    } else {
      this.navCtrl.navigateForward([
        '/single/' + this.TableName + '/' + data[this.PrimaryParamName],
      ]);
    }
  }

  async cekPesertaUjian(data) {
    let param = {
      ujian_id: data.id,
      user_id: this.profile.id,
    };

    await this.api
      .GET('custom&for=cekPesertaUjian', param)
      .then((res) => {
        if (res) {
          if (res.sudah === 'y' && res.pembahasan === 'y') {
            this.gotoDetailUjian(data, res.sudah);
          } else if (res.sudah === 'y' && res.pembahasan === 'n') {
            this.alertPembahasan(data);
          } else {
            this.alertUjian(data);
          }
          // this.gotoDetailUjian(data);
        } else {
          swal({
            title: 'Oops !',
            text: `Anda tidak terdaftar pada ujian ini, silahkan menghubungi admin.`,
            icon: 'warning',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async alertPembahasan(data) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Perhatian!',
      message: `Anda sudah melakukan ujian ini, untuk fitur evaluasi pembahasan silahkan hubungi admin.`,
      buttons: [
        {
          text: 'Ok',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async alertUjian(data) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Perhatian!',
      message: `Anda yakin inging melakukan ujian untuk ${data.nama} ?`,
      buttons: [
        {
          text: 'Tidak',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log("Confirm Cancel: blah");
          },
        },
        {
          text: 'Ya',
          handler: () => {
            // console.log("Confirm Okay");
            this.peringatanUjian(data);
          },
        },
      ],
    });

    await alert.present();
  }

  async alertGuest() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Akses Anda Terbatas!',
      subHeader: 'Untuk menikmati semua fitur aplikasi silahkan hubungi admin',
      message: `Hubungi Admin Sekarang ?`,
      buttons: [
        {
          text: 'Nanti',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log("Confirm Cancel: blah");
          },
        },
        {
          text: 'Ya',
          handler: () => {
            // console.log("Confirm Okay");
            // this.peringatanUjian(data);
          },
        },
      ],
    });

    await alert.present();
  }

  sendWa() {
    let hp = this.kontak_admin;
    this.iab.create(`https://api.whatsapp.com/send?phone=${hp}`, `_system`, {
      usewkwebview: 'yes',
    });
  }

  async peringatanUjian(data) {
    // console.log(data);
    const alert = await this.alertCtrl.create({
      cssClass: 'tutorial-ujian',
      header: 'Perhatian!',
      subHeader: `Petunjuk melakukan ujian`,
      message: `<ul><li>Silahkan mempersiapkan diri anda</li><li>Jika ini ujian listening silahkan gunakan <i>headset</i> dan pastikan HP anda tidak dalam silent mode.</li><li>Durasi waktu untuk ujian adalah <b>${data.waktu}</b> menit terhitung sejak anda menekan tombol <i>Lanjutkan</i> dibawah ini.</li><li>Geser/Swap untuk berpindah halaman soal</li></ul>`,
      buttons: [
        {
          text: 'Batal',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log("Confirm Cancel: blah");
          },
        },
        {
          text: 'Lanjutkan',
          handler: () => {
            // this.cekPesertaUjian(data);
            this.gotoDetailUjian(data);
          },
        },
      ],
    });

    await alert.present();
  }

  gotoDetailUjian(data, sudah: String = 'n') {
    let param: NavigationExtras = {
      queryParams: {
        id: data.id,
        waktu: data.waktu,
        durasi_mulai: data.durasi_mulai,
        file: data.file,
        keterangan: data.keterangan,
        sudah: sudah,
      },
    };

    this.navCtrl.navigateRoot([`/ujian/${data.id}`], param);
  }

  Goadd() {
    this.navCtrl.navigateForward(['/form/' + this.TableName + '/add/-1']);
  }

  scrollContent() {
    this.ionContent.scrollToTop(1000); //300 for animate the scroll effect.
  }

  openSearch() {
    this.searchPanel = true;
    setTimeout(() => this.searchbar.setFocus(), 100);
  }

  cancelSearch() {
    this.searchPanel = false;
  }

  goPresensi() {
    this.navCtrl.navigateForward(['/presensi']);
  }

  // To Top
  getScrollPos(pos: number) {
    if (pos > this.platform.height()) {
      this.ScollTop = true;
    } else {
      this.ScollTop = false;
    }
  }
}
