import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  filters: any;

  constructor() {}

  set(table_name, value: any = {}) {
    let tmp: any = localStorage.getItem("filters");
    if (tmp) {
      tmp = JSON.parse(tmp);
      tmp[table_name] = value;
      localStorage.setItem("filters", JSON.stringify(tmp));
    } else {
      let data = {};
      data[table_name] = value;
      localStorage.setItem("filters", JSON.stringify(data));
    }
  }

  get(table_name) {
    let tmp = localStorage.getItem("filters");
    if (tmp && JSON.parse(tmp)?.hasOwnProperty(table_name)) {
      let tmp = localStorage.getItem("filters");
      return tmp ? JSON.parse(tmp)[table_name] : {};
    } else {
      return {};
    }
  }

  remove(table_name) {
    let tmp = localStorage.getItem("filters");
    if (tmp && JSON.parse(tmp)?.hasOwnProperty(table_name)) {
      let tmp = JSON.parse(localStorage.getItem("filters"));
      delete tmp[table_name];
      localStorage.setItem("filters", JSON.stringify(tmp));
    }
  }
}
