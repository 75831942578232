export const Menu: any = [
  {
    title: "Materi",
    icon: "documents",
    img: "assets/icon/075-text-books.png",
    link: "/list/materi",
    // link: "/materi-jenjang/materi",
    role: "materi|list",
    divider: false,
  },
  {
    title: "Youtube",
    icon: "documents",
    img: "assets/icon/076-computer-1.png",
    link: "/list/youtube",
    role: "youtube|list",
    divider: false,
  },
  {
    title: "Latihan Soal Bimbel",
    icon: "book",
    img: "assets/icon/065-exam-2.png",
    link: "/list/latihan_soal",
    // link: "/materi-jenjang/latihan_soal",
    role: "latihan_soal|list",
    divider: false,
  },
  {
    title: "Latihan Soal",
    icon: "book",
    img: "assets/icon/065-exam-2.png",
    link: "/list/latihan_soal_nb",
    // link: "/materi-jenjang/latihan_soal_nb",
    role: "latihan_soal_nb|list",
    divider: false,
  },
  {
    title: "Ujian",
    icon: "document-text",
    img: "assets/icon/016-desk-chair.png",
    link: "/list/ujian",
    role: "ujian|list",
    divider: true,
  },
  {
    title: "Tentor",
    icon: "people",
    img: "assets/icon/099-teacher.png",
    link: "/list/tentor",
    role: "tentor|list",
    divider: false,
  },
  {
    title: "Siswa",
    icon: "person",
    img: "assets/icon/084-graduate.png",
    link: "/list/siswa",
    role: "siswa|list",
    divider: true,
  },
  // {
  //   title: "Jadwal",
  //   icon: "calendar",
  //   img: "assets/icon/026-calendar.png",
  //   link: "/jadwal",
  //   role: "jadwal|list",
  //   divider: false,
  // },
  // {
  //   title: "Menu Lainya",
  //   icon: "checkbox",
  //   img: "assets/icon/paket_program.svg",
  //   // link: "/list/presensi",
  //   link: "/presensi-list",
  //   role: "",
  //   divider: false,
  // },
  // {
  //   title: "Presensi",
  //   icon: "checkbox",
  //   img: "assets/icon/055-clock.png",
  //   // link: "/list/presensi",
  //   link: "/presensi-list",
  //   role: "presensi|list",
  //   divider: true,
  // },

  // Master ======================================================
  // {
  //   title: "Jenjang Pendidikan",
  //   icon: "school",
  //   img: "assets/icon/073-mortarboard.png",
  //   link: "/list/jenjang_pendidikan",
  //   role: "jenjang_pendidikan|list",
  //   divider: false,
  // },
  // {
  //   title: "Kelas",
  //   icon: "list",
  //   img: "assets/icon/056-students.png",
  //   link: "/list/kelas",
  //   role: "kelas|list",
  //   divider: false,
  // },
  // {
  //   title: "Mata Pelajaran",
  //   icon: "book",
  //   img: "assets/icon/015-folder.png",
  //   link: "/list/mapel",
  //   role: "mapel|list",
  //   divider: false,
  // },
  // {
  //   title: "Lokasi Bimbel",
  //   icon: "location",
  //   img: "assets/icon/039-library.png",
  //   link: "/list/lokasi",
  //   role: "lokasi|list",
  //   divider: true,
  // },
  // Master Paket Program ============================================
  // {
  //   title: "Paket",
  //   icon: "cube",
  //   img: "assets/icon/078-abc.png",
  //   link: "/list/paket",
  //   role: "paket|list",
  //   divider: false,
  // },
  // {
  //   title: "Program",
  //   icon: "ribbon",
  //   img: "assets/icon/002-diploma.png",
  //   link: "/list/program",
  //   role: "program|list",
  //   divider: false,
  // },
  // {
  //   title: "Pertemuan",
  //   icon: "chatbubbles",
  //   img: "assets/icon/096-asking.png",
  //   link: "/list/pertemuan",
  //   role: "pertemuan|list",
  //   divider: false,
  // },
  // {
  //   title: "Paket Program",
  //   icon: "medal",
  //   img: "assets/icon/077-calculator.png",
  //   link: "/list/paket_program",
  //   role: "paket_program|list",
  //   divider: false,
  // },
  {
    title: "Permintaan",
    icon: "medal",
    img: "assets/icon/085-post-it.png",
    link: "/list/m_permintaan",
    role: "m_permintaan|list",
    divider: false,
  },
  {
    title: "PR",
    icon: "medal",
    img: "assets/icon/085-post-it.png",
    link: "/list/homework",
    role: "homework|list",
    divider: false,
  },
];
