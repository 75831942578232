import { Injectable, ErrorHandler } from "@angular/core";
import axios, { AxiosInstance } from "axios";
import { Config } from "./config";
import swal from "sweetalert";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  BaseUrl: any;
  private axios: AxiosInstance;
  private errorHandler: ErrorHandler;
  constructor(public config: Config) {
    this.BaseUrl = this.config.baseUrl;
  }

  public async List(object, body: any = {}) {
    return await this.POST("list&object=" + object, body);
  }

  public async Update(object, body: any = {}) {
    return await this.POST("update&object=" + object, body, true);
  }

  public async Delete(object, body: any = {}) {
    return await this.POST("delete&object=" + object, body, true);
  }

  public async Create(object, body: any = {}) {
    if (object == "m_permintaan") {
      body.user_id = JSON.parse(localStorage.getItem("profile")).id;
    }
    return await this.POST("create&object=" + object, body, true);
  }

  public async View(object, body: any = {}) {
    return await this.POST("view&object=" + object, body);
  }

  public async Lookup(object, body: any = {}) {
    return await this.POST("lookup&object=" + object, body);
  }

  public async GET(url, params: any = {}) {
    let header = {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": this.config.LoginToken,
      },
      params: params,
    };

    return await axios
      .get(this.BaseUrl + url, header)
      .then((res) => {
        if (res.data.status == 202) {
          return res.data.res;
        } else {
          swal(res.data.title, res.data.message + " !!", "warning");
        }
      })
      .catch((err: any) => {
        swal("Oops!", "Koneksi Bermasalah !!", "error");
        // swal(
        //   "Oops!",
        //   ` ${JSON.stringify(err.message)}
        //     ${JSON.stringify(err.config.url)}
        //     ${JSON.stringify(err.config.data)}
        //   `,
        //   "error"
        // ); // bypass
      });
  }

  public async POST(url, body: any = {}, message: boolean = false) {
    let header = {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": this.config.LoginToken,
      },
    };
    return await axios
      .post(this.BaseUrl + url, body, header)
      .then((res) => {
        if (res.data.status == 202) {
          if (message) {
            swal(res.data.title, res.data.message + " !!", "success");
          }
          return res.data.res;
        } else {
          swal(res.data.title, res.data.message + " !!", "warning");
        }
      })
      .catch((err: any) => {
        swal("Oops!", "Koneksi Bermasalah !!", "error");
        // let error = JSON.stringify(err);
        // swal(
        //   "Oops!",
        //   ` ${JSON.stringify(err.message)}
        //     ${JSON.stringify(err.config.url)}
        //     ${JSON.stringify(err.config.data)}
        //   `,
        //   "error"
        // ); // bypass
      });
  }

  public async upload<T>(options: any): Promise<any> {
    console.log("options: ", options);
    try {
      let header = {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Content-Type": "application/json",
          "X-Authorization": this.config.LoginToken,
        },
      };

      const formData = new FormData();
      formData.append("files", options.file, `${options.name}`);
      const res: any = await axios.post(
        this.BaseUrl + `custom&for=upload`,
        formData,
        header
      );

      if (res.data.res.data) {
        return res.data.res.data;
      } else {
        return Promise.reject(res);
      }
    } catch (err) {
      console.log("err.response: ", err);
      return Promise.reject(err.response);
    }
  }
}
