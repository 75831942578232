import { Component, OnInit, ViewChild } from '@angular/core'
import { IonSlides, NavController } from '@ionic/angular'

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  @ViewChild('slides') slides:IonSlides;

  constructor(
    public navCtrl: NavController
  ) { }

  ngOnInit() { }

  GoLogin() {
    localStorage.setItem("landing", "no")
    this.navCtrl.navigateRoot(['login'])
  }

  next(){
    this.slides.slideNext();
  }


}
