import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "[permission]",
})
export class PermissionsDirective {
  @Input()
  permission: string;

  conf: any = [
    {
      role: "add",
      value: 1,
    },
    {
      role: "delete",
      value: 2,
    },
    {
      role: "edit",
      value: 4,
    },
    {
      role: "list",
      value: 8,
    },
    {
      role: "admin",
      value: 16,
    },
    {
      role: "view",
      value: 32,
    },
    {
      role: "search",
      value: 64,
    },
    {
      role: "import",
      value: 128,
    },
    {
      role: "lookup",
      value: 256,
    },
  ];
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  async ngOnInit() {
    let permissions = JSON.parse(localStorage.getItem("roles"));
    if (this.permission) {
      let value = this.permission.split("|");
      let permission_name = value[0].toString();
      let permission_action = value[1].toString();

      let val_permissions = permissions[permission_name][permission_action];

      if (val_permissions == 0) {
        this.el.nativeElement.remove();
        // await this.el.nativeElement.childNodes.forEach((node) => {
        //   this.renderer.removeChild(this.el.nativeElement, node);
        // });
      }
    }
  }
}
