import { Injectable, ErrorHandler } from '@angular/core';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class Config {
  readonly app_version_code = '1.1';
  appversion: any = '1.0';
  appname: any = 'Clinic Bimbel';
  LoginToken: any = '';

  // Prod
  baseUrl: string = 'https://app.clinicbimbel.com/apiv10.php?action=';
  fileUrl: string = 'https://app.clinicbimbel.com/files/';

  // Dev
  // baseUrl: string = 'http://localhost:9080/clinic-bimbel-web/apiv10.php?action=';
  // fileUrl: string = 'http://localhost:9080/clinic-bimbel-web/files/';

  deviceId: any;
  deviceData: any;
  notifikasiInterval: number = 20; // second
  refreshDataInterval: number = 40; // minute
  isNotifikasiBackgroundIntervalRandom: boolean = true; // max when false
  notifikasiBackgroundIntervalMin: number = 60; // second;
  notifikasiBackgroundIntervalMax: number = 120; // second;
  constructor(public platform: Platform) {}

  setNotifikasiInterval(interval: number) {
    this.notifikasiInterval = interval;
  }
  setDevice(device: any) {
    this.deviceData = device;
  }
  getDevice() {
    return this.deviceData;
  }
  getNotifikasiInterval() {
    return this.notifikasiInterval;
  }
  getNotifikasiBackgroundInterval() {
    if (this.isNotifikasiBackgroundIntervalRandom) {
      var interval = Math.floor(
        Math.random() *
          (this.notifikasiBackgroundIntervalMax -
            this.notifikasiBackgroundIntervalMin +
            1) +
          this.notifikasiBackgroundIntervalMin
      );
      return interval;
    } else {
      return this.notifikasiBackgroundIntervalMax;
    }
  }
}
