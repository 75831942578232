import { Component, OnInit } from '@angular/core'
import { NavController } from '@ionic/angular'
import { FormBuilder, Validators } from '@angular/forms'
import { ApiService } from 'src/service/api.service'
import { Authentication } from 'src/service/Authentication'
import { Util } from 'src/service/util'

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
})
export class ProfileEditComponent implements OnInit {
  FormUpdated: any
  Profile: any
  TableName: any = 'users'
  PageMeta: any = {}
  InputField: any = {}
  constructor(
    private formBuilder: FormBuilder,
    private navCtrl: NavController,
    public api: ApiService,
    public auth: Authentication,
    public util: Util,
  ) {
    this.Profile = this.auth.profile

    this.InputField.id = [this.Profile.id, Validators.required]
    this.InputField.nama = [this.Profile.nama, Validators.required]
    this.InputField.username = [this.Profile.username, Validators.required]
    this.InputField.email = [this.Profile.email, Validators.required]
    this.InputField.hp = [this.Profile.hp, Validators.required]
    this.InputField.tanggal_lahir = [this.Profile.tanggal_lahir]
    // this.InputField.jenis_kelamin = [this.Profile.jenis_kelamin]
    this.InputField.alamat = [this.Profile.alamat]
    this.InputField.foto = [this.Profile.foto]
    this.InputField.nik = [this.Profile.nik]
    this.InputField.password = [""]
    this.InputField.validasipassword = [""]
    this.util.FieldUpload.foto = { loading: false, filename: this.Profile.foto, fileshow: this.Profile.foto_display }

    if (this.InputField) {
      this.FormUpdated = formBuilder.group(this.InputField)
    }
  }


  ngOnInit() { }

  DateChange(e) {
    this.FormUpdated.controls.tanggal_lahir.setValue(this.util.ConvertDateSql(e.target.value))
  }
  async Capture() {
    await this.util.ChangeMediaUpload(this.TableName, 'foto')
  }

  async OnUpdated() {
    if (this.util.file_loading) {
      this.util.presentToast("Tunggu Sampai Upload Selesai")
      return
    }

    if (!this.util.ValidationForm(this.FormUpdated)) {
      return
    }
    if (this.FormUpdated.value.password != this.FormUpdated.value.validasipassword) {
      this.util.presentToast("Password Not Match !!")
      return
    }

    this.FormUpdated.value.foto = this.util.FieldUpload.foto.filename
    let update = await this.api.POST("ProfileUpdate", this.FormUpdated.value, true)
    if (update) {
      localStorage.setItem('profile', JSON.stringify(update))
      this.auth.profile = update
      this.navCtrl.pop()
    }
  }




}
