import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertController, MenuController, NavController } from "@ionic/angular";
import { ApiService } from "../../service/api.service";
import { Authentication } from "src/service/Authentication";
import { Util } from "../../service/util";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  LoginForm: any;
  showpassword = false;
  inputPassword = "password";
  iconpassword = "eye-off";
  data_login: any = { username: "", password: "" };
  kontak_admin: any;
  constructor(
    public formBuilder: FormBuilder,
    public navCtrl: NavController,
    public api: ApiService,
    public auth: Authentication,
    public util: Util,
    public menuCtrl: MenuController,
    public alertController: AlertController,
    private iab: InAppBrowser
  ) {
    if (localStorage.getItem("LoginForm")) {
      this.data_login = JSON.parse(localStorage.getItem("LoginForm"));
    }

    this.LoginForm = formBuilder.group({
      username: [this.data_login.username, Validators.required],
      password: [this.data_login.password, Validators.required],
      remember: [false],
    });
  }

  ngOnInit() {}

  ionViewDidEnter() {}

  Onlogin() {
    if (!this.util.ValidationForm(this.LoginForm)) {
      return;
    }
    this.util.presentLoading();
    let data_login = {
      username: this.LoginForm.value.username,
      password: this.LoginForm.value.password,
    };

    if (this.LoginForm.value.remember) {
      localStorage.setItem("LoginForm", JSON.stringify(data_login));
    }
    this.api
      .POST("login", data_login)
      .then(async (res: any) => {
        await localStorage.setItem("token", res.token);
        await localStorage.setItem("roles", JSON.stringify(res.roles));
        await localStorage.setItem("profile", JSON.stringify(res.profile));
        await this.auth.CekLogin(true);
        if (this.auth.isAdmin()) {
          this.menuCtrl.swipeGesture(true);
        } else {
          this.menuCtrl.swipeGesture(false);
        }

        this.navCtrl.navigateRoot(["/app"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  guest() {
    // if (!this.util.ValidationForm(this.LoginForm)) {
    //   return;
    // }
    this.util.presentLoading();
    let data_login = {
      username: "guest",
      password: "guest",
    };

    if (this.LoginForm.value.remember) {
      localStorage.setItem("LoginForm", JSON.stringify(data_login));
    }
    this.api
      .POST("login", data_login)
      .then(async (res: any) => {
        await localStorage.setItem("token", res.token);
        await localStorage.setItem("roles", JSON.stringify(res.roles));
        await localStorage.setItem("profile", JSON.stringify(res.profile));
        await this.auth.CekLogin();
        if (this.auth.isAdmin()) {
          this.menuCtrl.swipeGesture(true);
        } else {
          this.menuCtrl.swipeGesture(false);
        }

        this.navCtrl.navigateRoot(["/app"]);
        await this.getKontakAdmin();
        this.showAlertGuest();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async showAlertGuest() {
    const alert = await this.alertController.create({
      cssClass: "alert-guest",
      // header: "Alert",
      // subHeader: "Subtitle",
      message: `<img src="./assets/image/Logo.png" style="border-radius: 2px"></br><ion-text><h4 class='ion-text-center'>Selamat Datang di </br> Clinic Bimbel</h4><ion-text></br><ion-text><p class='ion-no-margin'>Segera daftar untuk menikmati semua fitur aplikasi.</p></br><pclass='ion-no-margin'>Daftar sekarang ?</p></ion-text>`,
      buttons: [
        {
          text: "Nanti",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Daftar",
          handler: () => {
            console.log("Confirm Okay");
            this.sendWa();
          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log("onDidDismiss resolved with role", role);
  }

  async getKontakAdmin() {
    await this.api
      .GET("custom&for=getKontakAdmin", {})
      .then((res) => {
        // console.log("Banner : ",res);
        this.kontak_admin = res.hp;
        localStorage.setItem("kontak_admin", this.kontak_admin);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  sendWa() {
    let hp = this.kontak_admin;
    this.iab.create(`https://api.whatsapp.com/send?phone=${hp}`, `_system`, {
      usewkwebview: "yes",
    });
  }

  ShowHidePassword(val: any) {
    if (!val) {
      this.inputPassword = "text";
      this.iconpassword = "eye-off";
      this.showpassword = true;
    } else {
      this.inputPassword = "password";
      this.iconpassword = "eye";
      this.showpassword = false;
    }
  }
}
