import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  LOCALE_ID,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicSelectableModule } from "ionic-selectable";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ForgotPasswordComponent } from "src/page/forgot-password/forgot-password.component";
import { LandingComponent } from "src/page/landing/landing.component";
import { LoginComponent } from "src/page/login/login.component";
import { ProfileEditComponent } from "src/page/profile-edit/profile-edit.component";
import { ScanQrComponent } from "src/page/scan-qr/scan-qr-component";
import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import { FileTransfer } from "@awesome-cordova-plugins/file-transfer/ngx";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";
import { ListComponent } from "src/component/list/list.component";
import { SingleComponent } from "src/component/single/single.component";
import { Util } from "src/service/util";
import { AddEditComponent } from "src/component/add-edit/add-edit.component";
import { ApiService } from "src/service/api.service";
import { Authentication } from "src/service/Authentication";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { CountdownModule } from "ngx-countdown";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { Geolocation } from "@awesome-cordova-plugins/geolocation/ngx";
import { NgCalendarModule } from "ionic2-calendar";
import { SharedModule } from "./shared/shared.module";
import { OneSignal } from "@awesome-cordova-plugins/onesignal/ngx";
import { AppVersion } from "@awesome-cordova-plugins/app-version/ngx";

import localId from "@angular/common/locales/id";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localId);

import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { UploadComponent } from "src/component/upload/upload.component";

@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    LandingComponent,
    LoginComponent,
    ProfileEditComponent,
    ScanQrComponent,
    ListComponent,
    SingleComponent,
    AddEditComponent,
    UploadComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    CountdownModule,
    IonicSelectableModule,
    SharedModule,
    NgCalendarModule,
    PdfJsViewerModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    FileTransfer,
    PhotoViewer,
    Util,
    ApiService,
    Authentication,
    PdfViewerModule,
    InAppBrowser,
    Geolocation,
    OneSignal,
    AppVersion,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: LOCALE_ID,
      useValue: "id-ID",
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
