import { Component } from '@angular/core'
import { Authentication } from 'src/service/Authentication'
import { NavController } from '@ionic/angular'
import swal from 'sweetalert';
import { Util } from 'src/service/util'



@Component({
  selector: "app-profile",
  templateUrl: "profile.html",
  styleUrls: ["profile.scss"],
})
export class Profile {
  TableName: any = "users";

  constructor(
    public auth: Authentication,
    public navCtrl: NavController,
    public util: Util
  ) {}
  doRefresh(event: any) {
    event.target.complete();
  }

  ionViewDidEnter() {}

  loggout() {
    swal({
      title: "LOG OUT",
      text: "Anda yakin ingin Logout ?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        Cancel: { text: "Batal", className: "danger" },
        confirm: { text: "Logout", className: "" },
      },
    }).then((value) => {
      if (value == true) {
        this.auth.logout();
        swal({
          text: "Logout Success",
          icon: "success",
          timer: 2000,
          buttons: {
            visible: false,
          },
        });
      }
    });
  }
  goEdit() {
    this.navCtrl.navigateForward(["/profile-edit"]);
  }

  async changePhoto() {
    await this.util.ChangeMediaUpload(this.TableName, "foto");
  }
}
