import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { HariPipe } from "./pipes/hari.pipe";
import { PermissionsDirective } from "./directives/permissions.directive";

@NgModule({
  declarations: [HariPipe, PermissionsDirective],
  imports: [CommonModule],
  exports: [HariPipe, PermissionsDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
