import {
  InAppBrowser,
  InAppBrowserOptions,
} from "@awesome-cordova-plugins/in-app-browser/ngx";
import { ComponentFactoryResolver, Injectable } from "@angular/core";
import {
  ToastController,
  LoadingController,
  ActionSheetController,
  AlertController,
} from "@ionic/angular";
import * as moment from "moment";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";
import { FormBuilder, Validators } from "@angular/forms";
import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
} from "@awesome-cordova-plugins/file-transfer/ngx";
import swal from "sweetalert";
import { Config } from "./config";
import { ApiService } from "./api.service";
import { Database } from "src/model/database";
import { AppVersion } from "@awesome-cordova-plugins/app-version/ngx";

@Injectable({
  providedIn: "root",
})
export class Util {
  Database: any = [];
  pagemeta: any = [];
  FormInput: any;
  FormField: any = {};
  TypeInput: any = "";
  complete: boolean = false;
  LookupData: any = {};
  LookupLimit: any = 15;
  LookupOffset: any = 0;
  LookupSearch: any;
  LookupChildFilter: any = {};
  LookupParentFilter: any = {};
  FieldData: any = {};
  ListField: any = [];
  BaseUrl: any;
  imagepic: any;
  file_loading: any = false;
  FieldUpload: any = {};

  // App

  v_code: string = "1.0";
  v_server: any;
  forceUpdate: boolean = false;

  constructor(
    private toast: ToastController,
    public loadingController: LoadingController,
    public photoViewer: PhotoViewer,
    public config: Config,
    public camera: Camera,
    public transfer: FileTransfer,
    public actionSheetCtrl: ActionSheetController,
    private formBuilder: FormBuilder,
    public api: ApiService,
    private appVersion: AppVersion,
    public alertController: AlertController,
    private iab: InAppBrowser
  ) {
    this.BaseUrl = this.config.baseUrl + "upload&object=";
  }

  InputFormBuilder(Action: any, TableName: any, FieldHide: any = "") {
    this.pagemeta = "";
    this.LookupChildFilter = {};
    this.LookupParentFilter = {};
    this.FieldUpload = {};
    this.FormField = {};
    this.TypeInput = [];
    this.ListField = [];
    this.Database = Database;
    this.pagemeta = this.Database[TableName];

    if (this.pagemeta.field) {
      for (let item of this.pagemeta.field) {
        if (
          item.list &&
          item.name != this.pagemeta.primary &&
          item.name != FieldHide
        ) {
          this.ListField.push(item);
        }
        if (
          item[Action] &&
          item.name != this.pagemeta.primary &&
          item.name != FieldHide
        ) {
          if (item.type == "FILE") {
            this.FieldUpload[item.name] = {
              loading: false,
              filename: "",
              fileshow: "",
            };
          } else if (item.type == "SELECT" || item.type == "RADIO") {
            if (item.relation) {
              item.type = "LOOKUP";
            }
          }
          // SET FORM INPUT
          let DefaultValue = "";
          if (item.default != "" && item.default != null) {
            if (item.default == "DATETIME") {
              item.default = this.datetime();
            } else if (item.default == "DATE") {
              item.default = this.date();
            } else if (item.default == "TIME") {
              item.default = this.time();
            }
            DefaultValue = item.default;
          }
          if (item.required && Action != "filtersearch") {
            this.FormField[item.name] = [DefaultValue, Validators.required];
          } else {
            this.FormField[item.name] = [DefaultValue];
          }
          // END SET FORM INPUT

          // SET LOOKUP FILTER
          item.LookupFilter = {};
          if (item.parent_filter.length != 0) {
            for (let parent_filter of item.parent_filter) {
              item.LookupFilter[parent_filter] = DefaultValue;

              // SET LOOKUP CHILD FILTER
              if (!this.LookupChildFilter[parent_filter]) {
                this.LookupChildFilter[parent_filter] = [];
                let a = this.LookupChildFilter[parent_filter];
                if (!a[item.name]) {
                  a.push(item.name);
                }
                this.LookupChildFilter[parent_filter] = a;
              } else {
                let a = this.LookupChildFilter[parent_filter];
                if (!a[item.name]) {
                  a.push(item.name);
                }
                this.LookupChildFilter[parent_filter] = a;
              }
              // END SET LOOKUP CHILD FILTER
            }
            this.LookupParentFilter[item.name] = item.LookupFilter;
          }
          // SET LOOKUP FILTER

          // SET SELECTABLE VALUE
          if (item.relation) {
            item.names_lkp = item.name + "s_lkp";
            this.FormField[item.names_lkp] = [""];
            item.Lookup = [{ relation: "", display: "" }];
            this.FieldData[item.names_lkp] = item;
          }
          this.TypeInput.push(item);
          this.FieldData[item.name] = item;
        }
      }
    }
    let DataReturn = {
      pagemeta: this.pagemeta,
      FormField: this.FormField,
      LookupChildFilter: this.LookupChildFilter,
      LookupParentFilter: this.LookupParentFilter,
      TypeInput: this.TypeInput,
      FieldData: this.FieldData,
      ListField: this.ListField,
    };
    this.FormInput = this.formBuilder.group(this.FormField);

    return DataReturn;
  }

  // ==================|LOOKUP|============================
  LookupChange(e: any, field: any) {
    this.FormInput.controls[field.name].setValue(e.value.relation);
    this.LookupOffset = 0;
    this.LookupSearch = "";

    if (this.LookupChildFilter[field.name]) {
      for (let child of this.LookupChildFilter[field.name]) {
        this.FormInput.controls[child].setValue("");
        this.LookupParentFilter[child][field.name] =
          this.FormInput.value[field.name];
        if (this.LookupParentFilter[child]) {
          for (let a in this.LookupParentFilter[child]) {
            this.LookupParentFilter[child][a] = this.FormInput.value[a];
          }
          let lkp = child + "s_lkp";
          this.FormInput.controls[lkp].setValue({ relation: "", display: "" });
        }
        this.Lookup(this.FieldData[child]);
      }
    }
  }

  InputChange(e: any, field: any) {
    if (
      field.type == "DATE" ||
      field.type == "DATETIME" ||
      field.type == "TIME"
    ) {
      let value = this.convertDateTime(e.target.value);
      field.type == "DATE"
        ? this.FormInput.controls[field.name].setValue(value.date)
        : "";
      field.type == "DATETIME"
        ? this.FormInput.controls[field.name].setValue(value.datetime)
        : "";
      field.type == "TIME"
        ? this.FormInput.controls[field.name].setValue(value.time)
        : "";
    }
  }

  InfiniteLookup(e: any, field: any = "") {
    this.Lookup(field, e);
    // e.component.endInfiniteScroll()
  }
  SearchLookup(e: any, field: any = "") {
    this.LookupSearch = e.text;
    this.LookupOffset = 0;
    this.Lookup(field);
    e.component.endSearch();
  }

  async Lookup(field: any = "", e: any = false) {
    if (field.relation_table == "" || field.relation_table == undefined) return;
    let object = field.relation_table;
    let filter = this.LookupParentFilter[field.name];
    let body = {
      relation: field.relation_field,
      display: field.relation_display,
      limit: this.LookupLimit,
      offset: this.LookupOffset,
      search: this.LookupSearch,
      filter: filter,
    };
    let data = await this.api.Lookup(object, body);
    let DataArray = [];
    if (data) {
      if (this.LookupOffset == 0) {
        this.LookupData[field.name] = [];
      }
      if (data.length > 0) {
        this.LookupOffset = this.LookupOffset + this.LookupLimit;
      }
      if (this.LookupData[field.name]) {
        DataArray = this.LookupData[field.name];
      }
      for (let item of data) {
        DataArray.push(item);
      }
      if (e) {
        e.component.endInfiniteScroll();
      }
      this.LookupData[field.name] = DataArray;
    } else {
      if (e) {
        e.component.endInfiniteScroll();
      }
    }
  }
  // ==================|END LOOKUP|============================

  async ChangeMediaUpload(object, Field: any = "") {
    if (Camera["installed"]()) {
      let actionSheet = await this.actionSheetCtrl.create({
        header: "Media ",
        cssClass: "Action-controler",
        buttons: [
          {
            text: "Camera",
            cssClass: "text-devider",
            icon: "camera",
            handler: () => {
              this.TakePicture(object, Field);
            },
          },
          {
            text: "Galery",
            icon: "images",
            cssClass: "text-devider",
            handler: () => {
              this.OpenGalery(object, Field);
            },
          },
          {
            text: "Cancel",
            icon: "close",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    } else {
      this.presentToast("Kamera tidak tersedia");
    }
  }

  TakePicture(object, Field: any = "") {
    this.camera
      .getPicture({
        quality: 100,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
      })
      .then((img) => {
        this.file_loading = true;
        this.FieldUpload[Field] = { loading: true, filename: "", fileshow: "" };
        this.TransferFile(img, object, Field);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  OpenGalery(object, Field: any = "") {
    this.camera
      .getPicture({
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        destinationType: this.camera.DestinationType.DATA_URL,
        targetWidth: 1000,
        targetHeight: 1000,
      })
      .then((img) => {
        this.file_loading = true;
        this.FieldUpload[Field] = { loading: true, filename: "", fileshow: "" };
        this.TransferFile(img, object, Field);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  TransferFile(img, object, Field: any = "") {
    // create b64
    let imagefile = "data:image/jpg;base64," + img;
    console.log("imagefile: ", imagefile);
    // convert to blob
    const blobData = this.b64toBlob(img, `image/jpg`);
    // Build Data
    const data = {
      file: blobData,
      name: "temp-image.jpg",
    };

    this.api
      .upload(data)
      .then((res) => {
        console.log("res upload : ", res);
        this.file_loading = false;
        this.FieldUpload[Field] = {
          loading: false,
          filename: res.filename,
          fileshow: imagefile,
        };
      })
      .catch((err) => {
        console.log("err upload : ", err);
        swal("Upload Failed", "Error Connection !!", "error");
        this.file_loading = false;
      });

    // let imagefile = "data:image/jpg;base64," + img;
    // const fileTransfer: FileTransferObject = this.transfer.create();
    // let optionfile: FileUploadOptions = {
    //   fileKey: "files",
    //   fileName: "temp-image.jpg",
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     "X-Authorization": this.config.LoginToken,
    //   },
    // };
    // fileTransfer.upload(imagefile, this.BaseUrl + object, optionfile,true).then(
    //   (data) => {
    //     let resp = JSON.parse(data.response);
    //     if (resp.status == 202) {
    //       this.file_loading = false;
    //       this.FieldUpload[Field] = {
    //         loading: false,
    //         filename: resp.res.filename,
    //         fileshow: imagefile,
    //       };
    //     } else {
    //       swal("Oops!", resp.message, "warning");
    //       this.file_loading = false;
    //     }
    //   },
    //   (err) => {
    //     console.log('err upload : ', err);
    //     swal("Upload Failed", "Error Connection !!", "error");
    //     this.file_loading = false;
    //   }
    // );
  }

  b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    console.log("b64Data: ", b64Data);
    // const byteCharacters = atob(b64Data);
    const byteCharacters = atob(b64Data.replace(/-/g, "+").replace(/_/g, "/"));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async presentToast(
    message: any,
    position: any = "bottom",
    color: any = "dark"
  ) {
    const toast = await this.toast.create({
      message: message,
      duration: 2000,
      position: position,
      color: color,
      mode: "ios",
      cssClass: "toast-error",
    });
    toast.present();
  }

  PhotoViewer(UrlImg: any = "") {
    this.photoViewer.show(UrlImg);
  }

  ValidationForm(item: any, FieldData: any = "") {
    for (var key in item.controls) {
      let control = item.controls[key];
      let label = key.replace("_", " ");
      if (FieldData && FieldData[key] != "") {
        label = this.FieldData[key].label;
      }
      if (control.status !== "VALID") {
        if (control.errors) {
          if (control.errors.required) {
            this.presentToast(
              label.charAt(0).toUpperCase() +
                label.slice(1) +
                " Harus di isi !!"
            );
            return false;
          } else if (control.errors.maxlength) {
            this.presentToast(
              label.charAt(0).toUpperCase() +
                label.slice(1) +
                " Maksimal " +
                control.errors.maxlength.requiredLength +
                " Karakter"
            );
            return false;
          } else if (control.errors.minlength) {
            this.presentToast(
              label.charAt(0).toUpperCase() +
                label.slice(1) +
                " Maksimal " +
                control.errors.minlength.requiredLength +
                " Karakter"
            );
            return false;
          }
        }
      }
    }
    return true;
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: "loading-custom",
      message: "Please wait...",
      duration: 500,
    });
    await loading.present();
    await loading.onDidDismiss();
  }

  date() {
    moment.locale("id");
    return moment().format("YYYY-MM-DD");
  }
  time() {
    moment.locale("id");
    return moment().format("HH:mm:ss");
  }
  datetime() {
    moment.locale("id");
    return moment().format("YYYY-MM-DD H:mm:ss");
  }
  dateIndo(date) {
    if (date == null || date == "") {
      return;
    }
    moment.locale("id");
    return moment().format("DD MMMM YYYY");
  }
  ConvertDate(date) {
    if (date == null || date == "") {
      return;
    }
    moment.locale("id");
    return moment(date).format("DD MMMM YYYY");
  }
  ConvertDateSql(date) {
    if (date == null || date == "") {
      return;
    }
    moment.locale("id");
    return moment(date).format("YYYY-MM-DD");
  }

  convertDateTime(datetime: any) {
    let data = { date: "", time: "", datetime: "" };
    if (datetime == null || datetime == undefined || datetime == "") {
      return data;
    } else {
      moment.locale("id");
      data = {
        date: moment(datetime, "YYYY-MM-DD").format("YYYY-MM-DD"),
        time: moment(datetime, "HH:mm:ss").format("HH:mm:ss"),
        datetime: moment(datetime, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      };
      return data;
    }
  }

  convertBulanTahun(datetime: any) {
    let data = { date: "", time: "", datetime: "" };
    if (datetime == null || datetime == undefined || datetime == "") {
      return data;
    }
    moment.locale("id");
    data = {
      date: moment(datetime).format("YYYY-MM-01"),
      time: moment(datetime).format("HH:mm:ss"),
      datetime: moment(datetime).format("YYYY-MM-01 HH:mm:ss"),
    };
    return data;
  }

  InputDesimal(value: any) {
    var val = value.toString();
    val = val.toString().replace(/\,/g, "");
    val = val.toString().replace(/\./g, "");
    val = val.toString().replace(/\ /g, "");
    val = parseFloat(val).toFixed(0);
    if (isNaN(val) || val == undefined || val == null) {
      val = 0;
    }
    val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return val;
  }

  Separator(value) {
    var val = value.toString().replace(/\,/g, ".");
    var nilai = parseFloat(val).toFixed(2);
    nilai = nilai.toString().replace(/\,/g, ".");
    nilai = nilai.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return nilai;
  }

  async setNotif(data: any) {
    console.log(data);
    let respon: any;
    let url = "custom&for=prosesNotif";
    let body = data;
    await this.api
      .POST(url, body)
      .then((res) => {
        // console.log(res);
        respon = res;
      })
      .catch((err) => {
        console.log(err);
        respon = err;
      });
    return respon;
  }

  async cekUpdate() {
    let url = "custom&for=cekApp";

    await this.api
      .GET(url)
      .then(async (res) => {
        if (res.data) {
          this.v_server = parseFloat(res.data.version).toFixed(2);
          this.forceUpdate = res.data.force_update;
        }
        // console.log(res);

        // cek app version
        await this.appVersion
          .getVersionNumber()
          .then((value) => {
            // this.VersionCode = value;
            this.v_code = parseFloat(value).toFixed(2);
            // console.log("App Versi", this.v_code);
          })
          .catch((err) => {
            console.log("APP VERSIO ERR :", err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log("Dari Server : ", this.v_server);
    // console.log("App Ini : ", this.v_code);
    // console.log("Force Update ? : ", this.forceUpdate);

    if (this.v_code < this.v_server && this.forceUpdate === false) {
      // console.log(" Anda Harus Update !");
      const alert = await this.alertController.create({
        header: "Peringatan !",
        subHeader:
          "Aplikasi Clinic Bimbel versi terbaru sudah tersedia di Playstore",
        message: `Segera update Aplikasi ke versi ${this.v_server} untuk menikmati fitur terbaru`,
        mode: "ios",
        backdropDismiss: false,
        buttons: [
          {
            text: "Nanti",
            role: "cancel",
            handler: (blah) => {
              // console.log("Confirm Cancel: blah");
            },
          },
          {
            text: "Update",
            handler: () => {
              // console.log("Updating...");
              this.goupdate();
            },
          },
        ],
      });

      await alert.present();
    } else if (this.v_code < this.v_server && this.forceUpdate === true) {
      // console.log("harus update !");
      const alert = await this.alertController.create({
        header: "Peringatan !",
        subHeader: "Aplikasi anda sudah kadaluarsa",
        message: `Segera update Aplikasi ke versi ${this.v_server} untuk melanjutkan`,
        mode: "ios",
        backdropDismiss: false,
        buttons: [
          {
            text: "Keluar",
            role: "cancel",
            handler: (blah) => {
              // console.log("Confirm Cancel: blah");

              navigator["app"].exitApp();
            },
          },
          {
            text: "Update",
            handler: () => {
              this.goupdate();
              navigator["app"].exitApp();
            },
          },
        ],
      });
      await alert.present();
      // this.goupdate();
    } else {
      // console.log("else");
    }
  }

  private goupdate() {
    let opts: InAppBrowserOptions = {
      location: "yes", //Or 'no'
      hidden: "yes", //Or  'yes'
      // clearcache : 'yes',
      // clearsessioncache : 'yes',
      // zoom : 'yes',//Android only ,shows browser zoom controls
      // hardwareback : 'yes',
      // mediaPlaybackRequiresUserAction : 'no',
      // shouldPauseOnSuspend : 'no', //Android only
      // closebuttoncaption : 'Close', //iOS only
      // disallowoverscroll : 'no', //iOS only
      // toolbar : 'yes', //iOS only
      // enableViewportScale : 'no', //iOS only
      // allowInlineMediaPlayback : 'no',//iOS only
      // presentationstyle : 'pagesheet',//iOS only
      // fullscreen : 'yes',//Windows only
    };
    var url = `https://play.google.com/store/apps/details?id=com.clinicbimbel.teknisicilik`;
    var target = "_system";
    this.iab.create(url, target, opts);
  }

  public async appDetail() {
    let data = {
      appName: "Clinic Bimbel",
      appVersion: "0.0",
    };

    await this.appVersion
      .getAppName()
      .then((value) => {
        data.appName = value;
        console.log("APP NAME ?", value);
      })
      .catch((err) => {
        // console.log("APP VERSIO ERR :", err);
      });

    await this.appVersion
      .getVersionNumber()
      .then((value) => {
        data.appVersion = value;
        console.log("APP VERSION PLUGIN ?", value);
      })
      .catch((err) => {
        // console.log("APP VERSIO ERR :", err);
      });

    return data;
  }
}
