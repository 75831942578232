import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ApplicationRef,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
} from '@ionic/angular';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { CommonModule } from '@angular/common';
import { ApiService } from 'src/service/api.service';
import { Message } from 'src/service/message';

@Component({
  selector: 'app-upload',
  // standalone: true,
  // imports: [CommonModule],
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @Output() fileChange = new EventEmitter<Object>();

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  @Input() buttonTemplate: TemplateRef<any>;

  @Input() isFile = false;
  @Input() isGalery = true;
  @Input() isCamera = true;
  @Input() buttonSize: String = '';
  @Input() showLabel = true;
  @Input() cssClass = '';
  @Input() multi = false;

  @Input() allowed: any = [];

  @Input() skipUpload = false;

  mimeType = 'image/*';

  MAX_UPLOAD_FILE = environment.maxUploadFile;
  MAX_UPLOAD_FILE_COUNT = environment.maxUploadFileCount;

  constructor(
    private loadingCtrl: LoadingController,
    private api: ApiService,
    private actionSheetController: ActionSheetController,
    private message: Message
  ) {}

  ngOnInit() {
    if (this.isFile) {
      this.mimeType = 'image/*,.pdf';
    }

    if (this.allowed.length > 0) {
      let newMime = '';
      newMime = this.allowed.join();
      this.mimeType = newMime;
    }
  }

  // =========================================NEW Upload File====================================================
  async selectFile(server: number = 1, max_size: any = this.MAX_UPLOAD_FILE) {
    const fileInput = this.fileInput.nativeElement;
    return new Promise(async (resolve, reject) => {
      const buttons = [];

      if (this.isGalery) {
        buttons.push({
          text: 'Galery',
          icon: 'image',
          handler: () => {
            this.fileInput.nativeElement.value = '';
            fileInput.click();
          },
        });
      }
      if (this.isCamera) {
        buttons.push({
          text: 'Kamera',
          icon: 'camera',
          handler: () => {
            this.addImage(CameraSource.Camera, server, max_size)
              .then((res) => {
                this.reset(res);
              })
              .catch((err) => {
                this.reset(err);
              });
          },
        });
      }

      if (buttons.length === 0) {
        this.addImage(CameraSource.Camera, server, max_size)
          .then((res) => {
            this.reset(res);
          })
          .catch((err) => {
            this.reset(err);
          });
      } else {
        const actionSheet = await this.actionSheetController.create({
          header: 'Pilih Sumber Media :',
          buttons,
          cssClass: 'action-file',
        });
        await actionSheet.present();
      }
    });
  }

  //Html Input File
  async onChange(event) {
    const max_size: any = this.MAX_UPLOAD_FILE;

    const files = event.target.files;

    if (files.length > 1 && files.length <= this.MAX_UPLOAD_FILE_COUNT) {
      const datas = [];
      for (const f of files) {
        const file = f;
        const file_size = file.size / 1000000;

        const data = {
          file,
          ext: '',
          name: file.name,
        };

        if (file_size > max_size) {
          this.message.presentToast(
            `Ukuran file terlalu besar, maximal ukuran ${max_size}MB.`
          );
        } else {
          const result = await this.upload(data);
          this.fileChange.emit(result);
          datas.push(result);
        }
      }
      this.fileInput.nativeElement.value = '';
    } else if (files.length === 1) {
      const file = event.target.files[0];
      const file_size = file.size / 1000000;

      const data = {
        file,
        ext: '',
        name: file.name,
      };

      if (file_size > max_size) {
        this.message.presentToast(
          `Ukuran file terlalu besar, maximal ukuran ${max_size}MB.`
        );
      } else {
        // const result = await this.upload(data);
        // this.fileChange.emit(result);
        if (this.skipUpload) {
          const result = {
            file: data.file.name,
            preview: await this.fileToDataURL(data.file),
            mentahan: data,
          };
          this.fileChange.emit(result);
          // this.api.upload(data);
        } else {
          const result = await this.upload(data);
          this.fileChange.emit(result);
        }
      }
      this.fileInput.nativeElement.value = '';
    } else {
      this.message.presentToast(
        `Jumlah maksimal file yang dapat dipilih adalah ${this.MAX_UPLOAD_FILE_COUNT}`
      );
    }
  }

  //Handle upload html input file
  async upload(data) {
    return new Promise(async (resolve, reject) => {
      const loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        message: 'Mengunggah...',
        translucent: true,
        cssClass: 'custom-class custom-loading',
        backdropDismiss: false,
        mode: 'md',
      });
      await loading.present();

      this.api
        .upload(data)
        .then((res) => {
          loading.dismiss();
          return resolve(res);
        })
        .catch((err) => {
          loading.dismiss();
          return reject(err);
        });
    });
  }

  //Native Camera
  async addImage(source: CameraSource, server, max_size) {
    return new Promise(async (resolve, reject) => {
      const image = await Camera.getPhoto({
        quality: 50,
        allowEditing: true,
        resultType: CameraResultType.Base64,
        webUseInput: true,
        source,
      });

      const blobData = this.b64toBlob(
        image.base64String,
        `image/${image.format}`
      );
      const imageName = new Date().getTime() + '.' + image.format;
      const file_size = blobData.size / 1000000;

      const data = {
        file: blobData,
        // ext: blobData.type,
        name: imageName,
      };

      if (file_size > max_size) {
        this.message.presentToast(
          `Ukuran file terlalu besar, maximal ukuran ${max_size}MB.`
        );
      } else {
        if (this.skipUpload) {
          const result = {
            file: data.name,
            preview: await this.blobToDataURL(data.file),
            mentahan: data,
          };
          return resolve(result);
        } else {
          const loading = await this.loadingCtrl.create({
            spinner: 'crescent',
            message: 'Uploading...',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            backdropDismiss: false,
            mode: 'md',
          });

          await loading.present();

          this.api
            .upload(data)
            .then((res) => {
              loading.dismiss();
              return resolve(res);
            })
            .catch((err) => {
              loading.dismiss();
              return reject(err);
            });
        }
      }
    });
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async reset(value) {
    this.fileChange.emit(value);
    this.fileInput.nativeElement.value = '';
  }

  //java script file to url
  fileToDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  //java script blob to url
  blobToDataURL(blobData) {
    const src = URL.createObjectURL(blobData);
    return src;
  }
}
