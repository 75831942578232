import { Injectable } from '@angular/core';
import {
  AlertController,
  AlertOptions,
  ToastController,
  ToastOptions,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class Message {
  constructor(private alert: AlertController, private toast: ToastController) {}

  async presentToast(text: string = ``) {
    const toast = await this.toast.create({
      message: text,
      duration: 1500,
      position: 'top',
    });
    await toast.present();
  }

  async presentAlert(opts: AlertOptions) {
    const alert = await this.alert.create(opts);
    await alert.present();
  }
}
