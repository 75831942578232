import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hari'
})
export class HariPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == 2) {
      return "Senin";
    } else if (value == 3) {
      return "Selasa";
    } else if (value == 4) {
      return "Rabu";
    } else if (value == 5) {
      return "Kamis";
    } else if (value == 6) {
      return "Jum'at";
    } else if (value == 7) {
      return "Sabtu";
    } else if (value == 1) {
      return "Minggu";
    }else{
      return " - ";
    }
    // return 'a';
  }

}
