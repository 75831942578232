import { Injectable } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

import { Util } from "./util";
import { Config } from "./config";
import { OneSignal } from "@awesome-cordova-plugins/onesignal/ngx";
import { ApiService } from "src/service/api.service";

import { Device } from "@capacitor/device";
import { OnesignalHelper } from "./onesignalHelper";

@Injectable({
  providedIn: "root",
})
export class Authentication implements CanActivate {
  login = false;
  token: any;
  profile: any;
  roles: any;
  landing: any = true;
  fileUrl: string;
  level_id: any;
  uuid: string;
  constructor(
    public navCtrl: NavController,
    public router: Router,
    private util: Util,
    public config: Config,
    private oneSignal: OneSignal,
    private api: ApiService,
    private platform: Platform,
    private os: OnesignalHelper
  ) {
    this.fileUrl = config.fileUrl;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let ParamMaps = next.paramMap;
    let ComponentName = next.data.action;

    if (this.login && next.data.login) {
      if (next.data.permision == "app") {
        return true;
      } else if (
        ComponentName == "list" ||
        ComponentName == "view" ||
        ComponentName == "add-edit" ||
        ComponentName == "master-add"
      ) {
        let tablename = next.params.tablename;

        if (next.data.action == "list") {
          if (this.roles[tablename].list == 1) {
            return true;
          }
          this.util.presentToast(
            "Anda tidak memiliki hak akses ! ",
            "bottom",
            "dark"
          );
        } else if (next.data.action == "view") {
          if (this.roles[tablename].view == 1) {
            return true;
          }
          this.util.presentToast(
            "Anda tidak memiliki hak akses ! ",
            "bottom",
            "dark"
          );
        } else if (next.data.action == "add-edit") {
          if (this.roles[tablename].add == 1 && next.params.action == "add") {
            return true;
          } else if (
            this.roles[tablename].edit == 1 &&
            next.params.action == "edit"
          ) {
            return true;
          }
          this.util.presentToast(
            "Anda tidak memiliki hak akses ! ",
            "bottom",
            "dark"
          );
        } else if (next.data.action == "master-add") {
          if (this.roles[tablename].add == 1) {
            return true;
          }
          this.util.presentToast(
            "Anda tidak memiliki hak akses ! ",
            "bottom",
            "dark"
          );
        } else {
          this.util.presentToast("Anda tidak memiliki hak akses ! ");
          return false;
        }
      } else if (this.roles[next.data.permision][next.data.value] == 1) {
        return true;
      } else {
        this.util.presentToast("Anda tidak memiliki hak akses ! ", "", "dark");
        return false;
      }
    } else {
      this.util.presentToast("Anda tidak memiliki hak akses ! ", "", "dark");
      return false;
    }
  }

  async CekLogin(afterLogin: boolean = false) {
    await this.getId();
    this.login = false;
    this.token = await localStorage.getItem("token");
    this.profile = await JSON.parse(localStorage.getItem("profile"));
    this.roles = await JSON.parse(localStorage.getItem("roles"));
    if (this.token != null || this.profile != null) {
      this.login = true;
      this.config.LoginToken = this.token;
      this.navCtrl.navigateRoot("/app");
      this.level_id = this.profile.level_id;

      if (!this.platform.is("mobileweb")) {
        // Mode debug webmobile
        if (afterLogin) {
          await this.setTag();
          await this.authTag();
        } else {
          await this.authTag();
        }
      } else {
        // running device
        await this.cekProfile();
      }
    } else {
      await this.noAuth();
    }
  }

  async getId() {
    await Device.getId().then((res) => {
      this.uuid = res.uuid;
    });
  }

  async noAuth() {
    // this.navCtrl.navigateRoot(["/login"]);
    if (!this.landing) {
      this.navCtrl.navigateRoot(["/login"]);
    } else {
      this.landing = false;
      if (localStorage.getItem("landing") == "no") {
        this.navCtrl.navigateRoot(["/login"]);
      } else {
        this.navCtrl.navigateRoot(["/landing"]);
      }
    }
  }

  async cekProfile() {
    let object = "users";
    let body = {
      id: this.profile.id,
    };
    await this.api
      .View(object, body)
      .then(async (res) => {
        if (res) {
          this.navCtrl.navigateRoot("/app");
        } else {
          await this.noAuth();
        }
      })
      .catch((err) => {
        console.log("Error cek login", err);
      });
  }

  async authTag() {
    let param = {
      user_id: this.profile.id,
      player_id: this.os.getIds(),
    };

    await this.api
      .GET("custom&for=authUserNotif", param)
      .then(async (res) => {
        console.log("RESPON auth profile id", res);
        if (res.success) {
          // Jika Berhasil lanjutkan
          this.navCtrl.navigateRoot("/app");
        } else {
          // Jika gagal/tidak sama, kembali ke login
          await this.noAuth();
        }
      })
      .catch((err) => {
        console.log("ERR AUTH DEVICE USER : ", err);
      });
  }

  async setTag() {
    // await this.oneSignal.sendTags(this.profile);
    delete this.profile.password;
    this.os.sendTag(this.profile);
    let param = {
      user_id: this.profile.id,
      player_id: this.os.getIds(),
    };
    await this.api
      .GET("custom&for=updateUserNotif", param)
      .then((res) => {
        console.log("Res Update uuid : ", res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  isAdmin() {
    if (this.level_id == "-1" || this.level_id == "30") {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.login = false;
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("profile");
    this.navCtrl.navigateRoot(["/login"]);
  }
}
