import { Component, NgZone } from "@angular/core";

import {
  Platform,
  NavController,
  ToastController,
  MenuController,
  AlertController,
  ModalController,
} from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { routes } from "./app-routing.module";
import { Authentication } from "../service/Authentication";
import { Menu } from "src/model/menu";
import { OneSignal } from "@awesome-cordova-plugins/onesignal/ngx";

import { Util } from "src/service/util";
import { ModaNotifPage } from "./page/modal/moda-notif/moda-notif.page";
import { Router, RouterEvent } from "@angular/router";
import { environment } from "src/environments/environment";

import { StatusBar, Style } from "@capacitor/status-bar";

import { App } from "@capacitor/app";
import { OnesignalHelper } from "src/service/onesignalHelper";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  backCounter: number = 0;
  menus: any = [];

  jadwal = {
    jadwal: [
      {
        hari: "1",
        nama_tentor: "tentor1",
        nama_kelas: "6",
        nama_jenjang: "SD",
        nama_mapel: "b",
        tentor_id: "34",
        paket_siswa_id: "29",
        paket_id: "5",
        na: "n",
        nama_siswa: "Ahmad",
        jenjang_id: "13",
        nama_durasi_paket: "60",
        jam: "07:00:00",
        id: "119",
        tanggal: "2021-04-25",
        nama_paket: "Regular",
        kelas_id: "6",
        siswa_id: "37",
        status: "0",
      },
      {
        hari: "1",
        nama_tentor: "tentor1",
        nama_kelas: "6",
        nama_jenjang: "SD",
        nama_mapel: "a",
        tentor_id: "34",
        paket_siswa_id: "29",
        paket_id: "5",
        na: "n",
        nama_siswa: "Ahmad",
        jenjang_id: "13",
        nama_durasi_paket: "60",
        jam: "11:00:00",
        id: "118",
        tanggal: "2021-04-25",
        nama_paket: "Regular",
        kelas_id: "6",
        siswa_id: "37",
        status: "0",
      },
      {
        hari: "1",
        nama_tentor: "tentor1",
        nama_kelas: "6",
        nama_jenjang: "SD",
        nama_mapel: "c",
        tentor_id: "34",
        paket_siswa_id: "29",
        paket_id: "5",
        na: "n",
        nama_siswa: "Ahmad",
        jenjang_id: "13",
        nama_durasi_paket: "60",
        jam: "13:00:00",
        id: "120",
        tanggal: "2021-04-25",
        nama_paket: "Regular",
        kelas_id: "6",
        siswa_id: "37",
        status: "0",
      },
    ],
  };
  activePath: string;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private navctrl: NavController,
    private toast: ToastController,
    private menuControl: MenuController,
    public auth: Authentication,
    private oneSignal: OneSignal,
    public alertController: AlertController,
    public util: Util,
    public modalCtrl: ModalController,
    private router: Router,
    private onesignal: OnesignalHelper
  ) {
    this.initializeApp();
    // let mobileweb = this.platform.is("mobileweb");
    // console.log("MOBILE WEB ? ", mobileweb);

    this.platform.backButton.subscribeWithPriority(10, () => {
      console.log("back button pressed", this.backCounter);

      if (this.backCounter == 0) {
        this.backCounter++;
        console.log("PATH : ", this.activePath);

        this.navctrl.pop();
        setTimeout(() => {
          this.backCounter = 0;
        }, 3000);
      } else {
        this.presentToast();
        this.backCounter++;
        if (this.backCounter > 1) {
          App.exitApp();
        }
        setTimeout(() => {
          this.backCounter = 0;
        }, 3000);
      }
    });
    // this.statusBar.styleBlackOpaque();
    // this.statusBar.backgroundColorByHexString("#009563");
    this.menus = Menu;

    this.router.events.subscribe((event: RouterEvent) => {
      this.activePath = event.url;
    });
  }

  async initializeApp() {
    this.platform.ready().then(async (res) => {
      console.log("PLATFORM READY", res);

      // this.modalNotif(
      //   "Pemberitahuan Baru !",
      //   "Besok ada jadwal les, silahkan konfirmasi kehadiran anda.",
      //   this.jadwal
      // );
      if (res !== "dom") {
        await StatusBar.setBackgroundColor({
          color: "#009563",
        });
        StatusBar.show();
        // this.setupNotif();
        this.onesignal.init();
        // if (environment.production) 
        this.util.cekUpdate();
      }
      this.auth.CekLogin();
      this.splashScreen.hide();
      // this.notifOptions();
    });
  }

  async presentToast() {
    const toast = await this.toast.create({
      message: "Press back again to exit",
      duration: 1000,
      position: "bottom",
    });
    toast.present();
  }

  openmenu(val: any) {
    this.menuControl.close();
    this.navctrl.navigateForward([val]);
  }

  openMenu() {}

  setupNotif() {
    this.oneSignal.startInit(
      "faf1fec9-9f7d-4538-86ed-9101bc69f025",
      "735310560748"
    );

    this.oneSignal.setSubscription(true);

    this.oneSignal.inFocusDisplaying(
      this.oneSignal.OSInFocusDisplayOption.None
    );

    this.oneSignal.handleNotificationReceived().subscribe((data) => {
      console.log("resceived notif", data);
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData.data;
      let jenis_notif = data.payload.additionalData.data.jenis_notif_received;
      if (jenis_notif == 1) {
        this.modalNotif(title, msg, additionalData, additionalData.notif_id);
      } else if (jenis_notif == 3) {
        this.alertPresensi(msg, title, additionalData);
      } else {
        this.showToast(title, msg, additionalData);
      }
    });

    this.oneSignal.handleNotificationOpened().subscribe((data) => {
      console.log("OPENED DATA NOTIF : ", data);
      // this.gotoNotif();
    });

    this.oneSignal.endInit();
  }

  async modalNotif(title, msg, data, notif_id) {
    const modal = await this.modalCtrl.create({
      component: ModaNotifPage,
      cssClass: "modalNotif",
      showBackdrop: true,
      componentProps: {
        title: title,
        msg: msg,
        jadwal: data.jadwal,
        notif_id: notif_id,
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        // this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });

    return await modal.present();
  }

  async notifOptions(title, subtitle, data) {
    // let title = "Pesan Baru !";
    // let subtitle = "Akan ada bimbel pada hari senin jam 12 besok.";
    let message = "silahkan pilih kehadiran anda..";
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: `${title}`,
      subHeader: `${subtitle}`,
      message: `${message}`,
      backdropDismiss: false,
      buttons: [
        {
          text: "Libur",
          cssClass: "libur",
          handler: () => {
            // console.log("Libur Okay");
            data.jenis_notif = 1; //1 = kehadiran , 2 = pengingat
            data.jenis_kehadiran = 3; // 1 = hadir, 2 = reschedul, 3 = libur
            this.liburConfirm(data);

            console.log("Libur dari pilih", data);
          },
        },
        {
          text: "Reschedule",
          cssClass: "reschedule",
          handler: () => {
            // console.log("Reschedule Okay");
            data.jenis_notif = 1;
            data.jenis_kehadiran = 2;
            this.reschedulPrompt(data);
          },
        },
        {
          text: "Hadir",
          cssClass: "secondary",
          handler: (blah) => {
            // console.log("Hadir ");
            data.jenis_notif = 1;
            data.jenis_kehadiran = 1;
            this.hadirPrompt(data);
          },
        },
      ],
    });

    await alert.present();
  }

  async hadirPrompt(data) {
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: "Request Materi ?",
      backdropDismiss: false,
      inputs: [
        {
          name: "keterangan",
          type: "textarea",
          placeholder: "Silahkan isi request materi di sini jika ada...",
        },
      ],
      buttons: [
        {
          text: "Kirim",
          handler: (handler) => {
            data.keterangan = handler.keterangan;
            this.prosesNotif(data);
            // console.log("Confirm Ok", handler.value);
          },
        },
      ],
    });

    await alert.present();
  }

  async reschedulPrompt(data) {
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: "Request Jadwal ?",
      backdropDismiss: false,
      inputs: [
        {
          name: "keterangan",
          type: "textarea",
          placeholder: "Silahkan isi request materi di sini jika ada...",
        },
      ],
      buttons: [
        {
          text: "Kirim",
          handler: (handler) => {
            // console.log("Confirm Ok", handler.keterangan);
            data.keterangan = handler.keterangan;
            this.prosesNotif(data);
          },
        },
      ],
    });

    await alert.present();
  }

  async liburConfirm(data) {
    console.log("Libur dari Promp", data);
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: "Apakah anda yakin libur ?",
      message:
        "Anda terkena denda.</br>Jumlah denda akan disesuaikan Dengan perjanjian kerja",
      buttons: [
        {
          text: "Batal",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Libur",
          cssClass: "libur",
          handler: () => {
            this.prosesNotif(data);
          },
        },
      ],
    });

    await alert.present();
  }

  async prosesNotif(data) {
    let profile = JSON.parse(localStorage.getItem("profile"));
    let user_id = profile.id;
    let level_id = profile.level_id;
    if (user_id) {
      data.penerima_id = "30"; //admin
      data.pengirim_id = user_id;
      data.level_id = level_id;
    }
    await this.util.setNotif(data);
  }

  async showToast(title, msg, task) {
    const toast = await this.toast.create({
      header: `${title}`,
      message: `${msg}`,
      position: "top",
      duration: 5000,
      mode: "ios",
      cssClass: "toast-notif",
      buttons: [
        {
          side: "start",
          icon: "notifications-outline",
          role: "cancel",
          // text: "Lihat",
          handler: () => {
            console.log("Favorite clicked");
          },
        },
        {
          side: "end",
          text: "Lihat",
          handler: () => {
            console.log("Cancel clicked");
            this.gotoNotif();
          },
        },
      ],
    });
    toast.present();
  }

  async alertPresensi(title, subtitle, data) {
    // let title = "Pesan Baru !";
    // let subtitle = "Akan ada bimbel pada hari senin jam 12 besok.";
    // let message = "silahkan pilih kehadiran anda..";
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: `${subtitle}`,
      subHeader: `${title}`,
      // message: `${message}`,
      backdropDismiss: false,
      buttons: [
        {
          text: "Nanti",
          cssClass: "libur",
          handler: () => {
            // console.log("Libur Okay");
            data.jenis_notif = 1; //1 = kehadiran , 2 = pengingat
            data.jenis_kehadiran = 3; // 1 = hadir, 2 = reschedul, 3 = libur
            // this.liburConfirm(data);
            this.nantiAlert();
          },
        },
        {
          text: "Presensi Sekarang",
          cssClass: "primary",
          handler: (blah) => {
            // this.hadirPrompt(data);
            this.goToPresensi();
          },
        },
      ],
    });

    await alert.present();
  }

  async nantiAlert() {
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: `Peringatan!`,
      message: `Pastikan anda mengisi presensi nanti. </br></br> Presensi yang tidak diisi tidak akan dihitung.`,
      // message: `${message}`,
      backdropDismiss: false,
      buttons: [
        {
          text: "Ok",
          cssClass: "primary",
          handler: () => {
            console.log("presensi nanti.");
          },
        },
      ],
    });

    await alert.present();
  }

  gotoNotif() {
    this.navctrl.navigateForward(["/notification"]);
  }

  goToPresensi() {
    this.navctrl.navigateForward(["/presensi-list"]);
  }
}
