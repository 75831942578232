import { Injectable } from "@angular/core";
import {
  AlertController,
  ModalController,
  NavController,
  ToastController,
} from "@ionic/angular";
import OneSignal from "onesignal-cordova-plugin";
import NotificationReceivedEvent from "onesignal-cordova-plugin/dist/NotificationReceivedEvent";
import OSNotification from "onesignal-cordova-plugin/dist/OSNotification";
import { ModaNotifPage } from "src/app/page/modal/moda-notif/moda-notif.page";

@Injectable({
  providedIn: "root",
})
export class OnesignalHelper {
  id: string = "faf1fec9-9f7d-4538-86ed-9101bc69f025";

  deviceData = null;
  constructor(
    public modalCtrl: ModalController,
    public alertController: AlertController,
    private toast: ToastController,
    private navctrl: NavController
  ) {}

  init() {
    // Initialize the plugin
    OneSignal.setAppId(this.id);

    // Set an initial value for notification received in the foreground
    OneSignal.setNotificationWillShowInForegroundHandler((jsonData) => {
      let data: NotificationReceivedEvent = jsonData;
      let notification: OSNotification = data.getNotification();
      this.onReceived(notification);
    });

    // Show an alert for each notification received
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      console.log("notificationOpenedCallback: " + JSON.stringify(jsonData));
    });

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });

    // get device info
    OneSignal.getDeviceState((deviceData) => {
      this.deviceData = deviceData;
    });
  }

  getIds() {
    let deviceData = this.deviceData;
    return deviceData?.userId;
  }

  async onReceived(data) {
    let title = data.title;
    let msg = data.body;
    let additionalData = data.additionalData;
    let jenis_notif = data.additionalData["data"].jenis_notif_received;

    if (jenis_notif == 1) {
      this.modalNotif(title, msg, additionalData, additionalData["notif_id"]);
    } else if (jenis_notif == 3) {
      this.alertPresensi(msg, title, additionalData);
    } else {
      this.showToast(title, msg, additionalData);
    }
  }

  async modalNotif(title, msg, data, notif_id) {
    const modal = await this.modalCtrl.create({
      component: ModaNotifPage,
      cssClass: "modalNotif",
      showBackdrop: true,
      componentProps: {
        title: title,
        msg: msg,
        jadwal: data.jadwal,
        notif_id: notif_id,
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
      }
    });
    return await modal.present();
  }

  async sendTag(value) {
    OneSignal.sendTags(value);
  }

  async alertPresensi(title, subtitle, data) {
    // let title = "Pesan Baru !";
    // let subtitle = "Akan ada bimbel pada hari senin jam 12 besok.";
    // let message = "silahkan pilih kehadiran anda..";
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: `${subtitle}`,
      subHeader: `${title}`,
      // message: `${message}`,
      backdropDismiss: false,
      buttons: [
        {
          text: "Nanti",
          cssClass: "libur",
          handler: () => {
            // console.log("Libur Okay");
            data.jenis_notif = 1; //1 = kehadiran , 2 = pengingat
            data.jenis_kehadiran = 3; // 1 = hadir, 2 = reschedul, 3 = libur
            // this.liburConfirm(data);
            this.nantiAlert();
          },
        },
        {
          text: "Presensi Sekarang",
          cssClass: "primary",
          handler: (blah) => {
            // this.hadirPrompt(data);
            this.goToPresensi();
          },
        },
      ],
    });

    await alert.present();
  }

  async nantiAlert() {
    const alert = await this.alertController.create({
      cssClass: "notifOptions",
      header: `Peringatan!`,
      message: `Pastikan anda mengisi presensi nanti. </br></br> Presensi yang tidak diisi tidak akan dihitung.`,
      // message: `${message}`,
      backdropDismiss: false,
      buttons: [
        {
          text: "Ok",
          cssClass: "primary",
          handler: () => {
            console.log("presensi nanti.");
          },
        },
      ],
    });

    await alert.present();
  }

  gotoNotif() {
    this.navctrl.navigateForward(["/notification"]);
  }

  goToPresensi() {
    this.navctrl.navigateForward(["/presensi-list"]);
  }

  async showToast(title, msg, task) {
    const toast = await this.toast.create({
      header: `${title}`,
      message: `${msg}`,
      position: "top",
      duration: 5000,
      mode: "ios",
      cssClass: "toast-notif",
      buttons: [
        {
          side: "start",
          icon: "notifications-outline",
          role: "cancel",
          // text: "Lihat",
          handler: () => {
            console.log("Favorite clicked");
          },
        },
        {
          side: "end",
          text: "Lihat",
          handler: () => {
            console.log("Cancel clicked");
            this.gotoNotif();
          },
        },
      ],
    });
    toast.present();
  }
}
