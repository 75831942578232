import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "src/service/api.service";
import { IonContent, NavController } from "@ionic/angular";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { Util } from "src/service/util";
import swal from "sweetalert";
import { Database } from "src/model/database";
import { Config } from "src/service/config";
import {
  InAppBrowser,
  InAppBrowserOptions,
} from "@awesome-cordova-plugins/in-app-browser/ngx";
import { Howl, Howler } from "howler";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Authentication } from "src/service/Authentication";
import { ActionSheetController } from "@ionic/angular";
import { PDFWorker } from "pdfjs-dist";
import { Geolocation, GeolocationOptions } from "@awesome-cordova-plugins/geolocation/ngx";
import { UploadComponent } from "../upload/upload.component";
import * as lodash from "lodash";

(window as any).pdfWorkerSrc = PDFWorker;

@Component({
  selector: "app-single",
  templateUrl: "./single.component.html",
  styleUrls: ["./single.component.scss"],
})
export class SingleComponent implements OnInit {
  @ViewChild(IonContent) ionContent: IonContent;
  @ViewChild("buttonCamera") buttonCamera: UploadComponent;

  // For Youtube
  trustedVideoUrl: SafeResourceUrl;

  ScollTop: boolean = false;
  DataMaster: any = [];
  loading: any = true;
  loadingCount: any = new Array(5);
  Title: any = "";
  TableName: any = "";
  PrimaryParam: any = "";
  ListView: any = "";
  Database: any = [];
  PageMeta: any = [];
  ListField: any = [];
  edit: boolean = false;
  delete: boolean = false;
  DetailTable: any = {};
  DetailSegment: any = "master";
  DetailShow: boolean = false;
  DataDetail: any = [];
  filter: any = {};
  order: any = {};
  sort: any = "DESC";
  limit: any = 10;
  offset: any = 0;
  search: any;
  fileUrl: string;

  // pdf ================
  page: number = 1;
  totalPages: number = 0;
  isLoaded: boolean = false;
  zoom: any = 1;

  // Soal Sound ===========

  soalSound: any;
  sound: any;
  playingSound: boolean = false;

  // Permission
  canDelete: boolean = true;
  canEdit: boolean = true;
  pembahasan: any;
  level_id: any;

  pdfSrc: any;

  jawaban: any;
  jawabanPR: any;
  canJawabPR: boolean;
  fotoJawaban: any;

  constructor(
    public api: ApiService,
    public navCtrl: NavController,
    public util: Util,
    public activatedRoute: ActivatedRoute,
    public config: Config,
    private iab: InAppBrowser,
    private domSanitizer: DomSanitizer,
    private auth: Authentication,
    public actionSheetController: ActionSheetController,
    private geolocation: Geolocation
  ) {
    this.Database = Database;
    this.fileUrl = config.fileUrl;
    this.level_id = this.auth.level_id;
    // console.log(this.level_id);
    // this.pdfSrc = {
    //   // url: "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf",
    //   url: "http://app.clinicbimbel.com/pdftest.pdf",
    //   // httpHeaders: {
    //   //   // mode: "no-cors",
    //   //   // withCredentials: true,
    //   // },
    //   // withCredentials: false,
    //   // useWorkerFetch: true,
    //   // disableRange: true,
    //   // disableStream: true,
    //   // disableAutoFetch: true,
    // };
  }

  ngOnInit() {}
  // a(event: any) {
  //   this.pdfSrc = {
  //     url: "http://app.clinicbimbel.com/pdftest.pdf",
  //   };

  //   event.target.complete();
  // }

  ionViewWillEnter() {
    this.TableName = this.activatedRoute.snapshot.paramMap.get("tablename");
    this.PrimaryParam = this.activatedRoute.snapshot.paramMap.get("primary");
    this.PageMeta = this.Database[this.TableName];
    this.Title = this.PageMeta.table.label;
    this.edit = this.PageMeta.table.edit;
    this.delete = this.PageMeta.table.delete;
    this.ListView = [];

    // Permission Delete
    let roles = JSON.parse(localStorage.getItem("roles"));
    let role_delete = roles[this.TableName]["delete"];
    let role_edit = roles[this.TableName]["edit"];
    if (role_delete == 0) {
      this.canDelete = false;
    }

    if (role_edit == 0) {
      this.canEdit = false;
    }

    if (this.PageMeta.masterdetail) {
      for (let detail of this.PageMeta.masterdetail) {
        this.DetailTable[detail.DetailTable] = detail;
      }
    }

    // VIEW SETTING
    if (this.PageMeta.field) {
      for (let item of this.PageMeta.field) {
        if (item.view && item.name != this.PageMeta.primary) {
          item.name_view = item.name;
          if (item.relation) {
            item.name_view = item.relation_table + "_" + item.relation_display;
          } else {
            if (item.type == "SELECT" || item.type == "RADIO") {
              item.selector = {};
              for (let select of item.select) {
                item.selector[select.value] = select.label;
              }
            }
          }
          this.ListView.push(item);
        }
      }
    }
    // END VIEW SETTING

    this.GetData();
  }

  ionViewWillLeave() {
    if (this.playingSound == true) {
      this.stopSound();
    }
  }

  async GetData() {
    let body = {};
    body[this.PageMeta.primary] = this.PrimaryParam;
    let get = await this.api.View(this.TableName, body);
    if (get) {
      this.DataMaster = get;

      // Inisiasi Sound Mp3
      if (
        this.TableName == "latihan_soal" ||
        this.TableName == "latihan_soal_nb"
      ) {
        this.pembahasan = this.DataMaster.file_pembahasan;
        this.soalSound = this.DataMaster.audio;
        let full_url = this.fileUrl + this.soalSound;
        this.sound = new Howl({
          src: [full_url],
          autoplay: false,
        });
        // this.playingSound = true;
      }

      if (this.TableName == "homework") {
        let jawabanPR = await this.api.List(`homework_answer`, {
          homework_id: this.DataMaster.id,
          order: ["status"],
        });
        if (jawabanPR) {
          this.jawabanPR = lodash.orderBy(jawabanPR, "status",['desc']);
          // this.jawabanPR = jawabanPR;
        }
        console.log("jawabanPR: ", this.jawabanPR);

        //permission jawab
        let level_siswa_id = [20, 23, 24];
        if (!level_siswa_id.includes(this.level_id)) {
          this.canJawabPR = true;
        }
      }

      this.loading = false;
    } else {
      this.util.presentToast("Record Not Found !");
      this.navCtrl.pop();
    }
  }

  sanitize(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      "http://youtube.com/embed/" + url + "?enablejsapi=1&origin=localhost"
    );
  }

  // ========================| DETAIL |=============================
  doRefresh(event: any) {
    this.GetDetail(0, 0);
    event.target.complete();
  }
  SegmentChanged(e) {
    if (this.DetailSegment != "master") {
      this.Title = this.Database[this.DetailSegment].table.label;
      this.loading = true;
      this.filter[this.DetailTable[this.DetailSegment].DetailField] =
        this.DataMaster[this.DetailTable[this.DetailSegment].MasterField];
      this.GetDetail(0, 0);
    } else {
      this.Title = this.PageMeta.table.label;
      this.DetailShow = false;
    }
  }

  onCancelSearch(e) {
    this.loading = true;
    this.search = "";
    this.GetDetail(0, 0);
  }

  async GetDetail(offset: any = 0, originOffset: any = 0) {
    this.DetailShow = true;
    this.ListField = [];
    let Resp = await this.util.InputFormBuilder("list", this.DetailSegment);
    if (Resp) {
      this.Title = Resp.pagemeta.table.label;
      this.ListField = Resp.ListField;
    }
    this.offset = offset;
    if (offset == 0) {
      this.DataDetail = [];
    }
    let body = {
      order: this.order,
      sort: this.sort,
      limit: this.limit,
      offset: this.offset,
      filter: this.filter,
      search: this.search,
    };
    let get = await this.api.List(this.DetailSegment, body);
    if (get) {
      for (let item of get) {
        let no = 0;
        let record = [];
        item.thumbnail = false;
        // SET DATA INTO LIST
        for (let page of this.ListField) {
          no++;
          let display = page.name;
          if (page.relation) {
            display = page.relation_table + "_" + page.relation_display;
          } else {
            if (page.type == "SELECT" || page.type == "RADIO") {
              page.selector = {};
              for (let select of page.select) {
                item[page.name + "_" + select.value] = select.label;
              }
              display = page.name + "_" + item[page.name];
            }
          }
          // SET ARRAY DATA LIST
          item["list_" + no] = item[display];
          if (page.type == "FILE" && !item.thumbnail) {
            item.thumbnail = true;
            item.ThumbnailFile = item[display];
            item["list_" + no] = "";
          }
          if (no != 1) {
            record.push("list_" + no);
          }
          // END SET ARRAY DATA LIST
        }
        // END SET DATA INTO LIST

        item.records = record;
        this.DataDetail.push(item);
        if (this.DataDetail.length > 15) {
          this.ScollTop = true;
        }
      }
    } else {
      this.offset = originOffset;
    }
    setTimeout(() => {
      this.loading = false;
    }, 250);
  }

  loadData(event) {
    this.loading = false;
    setTimeout(() => {
      event.target.complete();
      let offset = this.offset + this.limit;
      this.GetDetail(offset, this.offset);
    }, 500);
  }

  Search(event) {
    this.loading = true;
    this.search = event.target.value;
    this.DataDetail = [];
    this.GetDetail(0, 0);
  }

  GoSinggle(data: any) {
    this.navCtrl.navigateForward([
      "/single/" +
        this.DetailSegment +
        "/" +
        data[this.Database[this.DetailSegment].primary],
    ]);
  }
  Goadd() {
    this.navCtrl.navigateForward(["/form/" + this.DetailSegment + "/add/-1"]);
  }

  scrollContent() {
    this.ScollTop = false;
    this.ionContent.scrollToTop(300); //300 for animate the scroll effect.
  }

  // ==============|END DETAIL DATA|==============

  Update() {
    this.navCtrl.navigateForward([
      "/form/" + this.TableName + "/edit/" + this.PrimaryParam,
    ]);
  }

  Delete() {
    swal({
      title: "Hapus ?",
      text: "Yakin Akan Menghapus Data ?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        confirm: { text: "Ya", className: "" },
        Cancel: { text: "Tidak", className: "danger" },
      },
    }).then((value) => {
      if (value == true) {
        let body = {};
        body[this.PageMeta.primary] = this.PrimaryParam;
        this.api.Delete(this.TableName, body).then((resp) => {
          this.navCtrl.pop();
        });
      }
    });
  }

  // PDF ===========================

  url_encode(val) {
    return encodeURIComponent(val);
  }

  onProgress(e: any) {}

  afterLoadComplete(e, a) {
    console.log("complete load pdf ", a);

    this.totalPages = e.numPages;
    this.isLoaded = true;
  }

  onErrorPdf(e: any) {
    console.log("error load pdf : ", e);
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  changepage(e) {
    this.page = e.detail.value;
  }

  toTop() {
    this.ionContent.scrollToTop(1500).then((res) => {
      this.page = 1;
    });
  }

  zoomIn() {
    this.zoom = this.zoom + 0.5;
  }

  zoomOut() {
    if (this.zoom > 0) {
      this.zoom = this.zoom - 0.5;
    }
  }

  async gotoMap(alamat, lat, long) {
    // console.log(alamat, lat, long);
    // this.iab.create(`geo:${lat},${long}`, `_system`, {
    //   usewkwebview: "yes",
    // });
    let config: GeolocationOptions = {
      enableHighAccuracy: true,
    };
    await this.geolocation
      .getCurrentPosition(config)
      .then((position) => {
        // let lat = resp.coords.latitude;
        // let long = resp.coords.longitude;
        // console.log(this.lat, this.long);
        let link =
          "geo://" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "?q=" +
          lat +
          "," +
          long;

        this.iab.create(`${link}`, `_system`, {
          usewkwebview: "yes",
        });
      })
      .catch((error) => {
        console.log(error);

        // swal({
        //   title: "Gagal !",
        //   text: `Gagal mengambil lokasi.`,
        //   icon: "error",
        //   closeOnClickOutside: true,
        //   closeOnEsc: true,
        // });
        // console.log("Error getting location", error);
      });
  }

  playSound() {
    this.sound.play();
    this.playingSound = true;
  }

  stopSound() {
    this.sound.pause();
    this.playingSound = false;
  }

  showPembahasan(file) {
    let param: NavigationExtras = {
      queryParams: {
        file: file,
      },
    };
    this.navCtrl.navigateForward(["/pembahasan"], param);
  }

  cekPembahasan() {
    let table = this.TableName;
    let file_pembahasan = this.DataMaster.file_pembahasan;
    if (this.level_id !== 20) {
      if (table === "latihan_soal" && file_pembahasan) {
        // console.log("Ada file pembahasan");
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async options() {
    const actionSheet = await this.actionSheetController.create({
      header: "Pilihan",
      cssClass: "my-custom-class",
      buttons: [
        // {
        //   text: "Cari",
        //   role: "search-outline",
        //   icon: "search",
        //   handler: () => {
        //     console.log("Delete clicked");
        //   },
        // },
        {
          text: "Buka PDF dari HP",
          icon: "open-outline",
          role: "open",
          handler: () => {
            console.log("Share clicked");

            let opts: InAppBrowserOptions = {
              usewkwebview: "yes",
              fullscreen: "yes",
              hideurlbar: "yes",
            };

            const browser = this.iab
              .create(
                this.fileUrl + this.url_encode(this.DataMaster?.file),
                `_blank`,
                opts
              )
              .on("loadstart")
              .subscribe((e) => {
                console.log(e);
              });
            // browser.on("loadstart").subscribe((event) => {
            //   console.log("Ev Start : ", event);
            // });
            // browser.on("loaderror").subscribe((event) => {
            //   console.log("Error : ", event);
            // });
          },
        },
        // {
        //   text: "Play (open modal)",
        //   icon: "caret-forward-circle",
        //   handler: () => {
        //     console.log("Play clicked");
        //   },
        // },
        // {
        //   text: "Favorite",
        //   icon: "heart",
        //   handler: () => {
        //     console.log("Favorite clicked");
        //   },
        // },
        // {
        //   text: "Cancel",
        //   icon: "close",
        //   role: "cancel",
        //   handler: () => {
        //     console.log("Cancel clicked");
        //   },
        // },
      ],
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log("onDidDismiss resolved with role", role);
  }

  async onSendJawaban() {
    await this.api.Create(`homework_answer`, {
      homework_id: this.DataMaster.id,
      answer: this.jawaban,
      foto: this.fotoJawaban,
    });

    this.jawaban = null;
    this.fotoJawaban = null;
    this.GetData();
  }

  async addFile(file) {
    if (file.filename) {
      console.log("file change ... : ", file);
      this.fotoJawaban = file.filename;
    }
  }
}
