import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/service/api.service";
import { NavController } from "@ionic/angular";
import { ActivatedRoute } from "@angular/router";
import { Util } from "src/service/util";
import { IonicSelectableComponent } from "ionic-selectable";

@Component({
  selector: "app-add-edit",
  templateUrl: "./add-edit.component.html",
  styleUrls: ["./add-edit.component.scss"],
})
export class AddEditComponent implements OnInit {
  title: any = "";
  SubmitTitle: any = "Simpan";
  data: any = [];
  loading: any = true;
  loadingCount: any = new Array(5);
  tablename: any = "";
  PrimaryParam: any = "";
  PrimaryParamName: any = "";
  pagemeta: any = {};
  edit: boolean = false;
  delete: boolean = false;
  action: any = "add";
  complete: boolean = false;
  TypeInput: any = [];
  SumbitDisabled: boolean = false;
  // Location ========
  lat: number;
  long: number;
  data_param: any;

  defaultDate = new Date().toISOString().substring(0, 10);
  constructor(
    public api: ApiService,
    public navCtrl: NavController,
    public util: Util,
    public activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.pagemeta = {};
    this.PrimaryParam = "";
    this.util.LookupChildFilter = {};
    this.util.LookupParentFilter = {};
    this.util.FieldUpload = {};
    this.util.FormField = {};
    this.util.TypeInput = [];

    this.tablename = this.activatedRoute.snapshot.paramMap.get("tablename");
    if (this.tablename == "m_permintaan") {
      this.SubmitTitle = "Kirim";
    }

    this.PrimaryParam = this.activatedRoute.snapshot.paramMap.get("primary");
    this.action = this.activatedRoute.snapshot.paramMap.get("action");
    let Resp = await this.util.InputFormBuilder(this.action, this.tablename);
    if (this.tablename == "presensi") {
      this.activatedRoute.queryParams.subscribe((p) => {
        // console.log(p);
        this.data_param = JSON.parse(p.data);
        console.log(this.data_param);
      });
      await this.setData();
    }
    if (Resp) {
      this.edit = Resp.pagemeta.table.edit;
      this.delete = Resp.pagemeta.table.delete;
      this.PrimaryParamName = Resp.pagemeta.primary;
      if (this.action == "add") {
        this.title = "Tambah " + Resp.pagemeta.table.label;
      } else {
        this.TypeInput = Resp.TypeInput;
        this.title = "Edit " + Resp.pagemeta.table.label;
        this.GetData();
      }
      if (this.util.FormField) {
        this.complete = true;
      }
    }
  }

  setData() {
    console.log(this.util.FormInput.controls);
    // console.log(this.data_param.durasi_paket);
    this.util.FormInput.controls.tentor_ids_lkp.setValue({
      relation: this.data_param.tentor_id,
      display: this.data_param.nama_tentor,
    });
    this.util.FormInput.controls.tentor_id.setValue(this.data_param.tentor_id);

    this.util.FormInput.controls.siswa_ids_lkp.setValue({
      relation: this.data_param.siswa_id,
      display: this.data_param.nama_siswa,
    });
    this.util.FormInput.controls.siswa_id.setValue(this.data_param.siswa_id);

    this.util.FormInput.controls.paket_ids_lkp.setValue({
      relation: this.data_param.paket_id,
      display: this.data_param.nama_paket,
    });
    this.util.FormInput.controls.paket_id.setValue(this.data_param.paket_id);

    this.util.FormInput.controls.mapel.setValue(this.data_param.nama_mapel);

    this.util.FormInput.controls.jam.setValue(this.data_param.jam);

    this.util.FormInput.controls.durasi_paket.setValue(
      this.data_param.durasi_paket
    );
  }

  ionViewWillEnter() {}

  InputChange(e: any, field: any) {
    this.util.InputChange(e, field);
  }

  // UPLOAD PICTURE
  TakePicture(field: any) {
    this.util.ChangeMediaUpload(this.tablename, field.name);
  }

  DeleteFile(field: any) {
    this.util.FieldUpload[field.name] = {
      loading: false,
      filename: "",
      fileshow: "",
    };
  }

  async GetData() {
    let body = {};
    body[this.PrimaryParamName] = this.PrimaryParam;
    let data = await this.api.View(this.tablename + "&update=1&", body);
    if (data) {
      if (this.TypeInput) {
        for (let item of this.TypeInput) {
          if (item.type == "LOOKUP") {
            let lkp = item.name + "s_lkp";
            this.util.FormInput.controls[lkp].setValue({
              relation: data[item.name],
              display: data[item.relation_table + "_" + item.relation_display],
            });
          } else if (item.type == "FILE") {
            this.util.FieldUpload[item.name].fileshow =
              data[item.name + "_display"];
            this.util.FieldUpload[item.name].filename = data[item.name];
          }
          this.util.FormInput.controls[item.name].setValue(data[item.name]);
        }
      }
      this.data = data;
      this.loading = false;
    } else {
      this.util.presentToast("Record Not Found !");
      this.navCtrl.pop();
    }
  }

  async OnSubmit() {
    if (this.util.file_loading) {
      this.util.presentToast("Tunggu Sampai Upload Selesai");
      return;
    }
    if (!this.util.ValidationForm(this.util.FormInput, this.util.FieldData)) {
      return;
    }
    this.SumbitDisabled = true;
    this.util.presentLoading();
    // console.log("Before Submit : ", this.util.FormInput.value);
    let RespAction = "";
    if (this.action == "add") {
      RespAction = await this.api.Create(
        this.tablename,
        this.util.FormInput.value
      );
    } else {
      this.util.FormInput.value[this.PrimaryParamName] = this.PrimaryParam;
      RespAction = await this.api.Update(
        this.tablename,
        this.util.FormInput.value
      );
    }

    if (this.tablename == "siswa" || this.tablename == "tentor") {
      this.navCtrl.pop();
    }

    if (RespAction) {
      this.navCtrl.pop();
    } else {
      this.SumbitDisabled = false;
    }
  }
}
